
import styled from "styled-components";
import {  StyledButton } from "../components";
import DisclaimerText from "../components/disclaimer";
import bgImg from '../assets/background_home.png'
import headerText from '../assets/header_text.png'
import { useNavigate } from "react-router";
import tgLogo from '../assets/tg_logo.png';
import xLogo from '../assets/x_logo_white.png';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const PageWrapperDiv = styled.div` 
    display: flex;    
    justify-content: center;
    align-content: center;    
    width: 100%;    
    background-image:  url(${bgImg});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) {      
        margin: 0px;
        width: 100%;                
        // margin-top: 85px;        
    }
`;

 const BodyTextDiv = styled.div` 
    display: flex;
    flex-direction: column;
    align-self: center;  
    justify-content: center;
    align-items: center;
    text-align: center;        
    font-size: 28px;         
    font-weight: bold;
    row-gap: 2em;
    font-family: Laila Bold;
    color: #060fce;      
    fontSize: 2em;
    width: 50%;   
    position: relative;    
    border-radius: 30px;
    min-height: 400px;
    padding: 10px;
    background-color: rgba(229,242,85,0.9);
    @media screen and (max-width: 768px) {        
      width: 90%;
      font-size: 1.3em;
      min-height: 500px;
      row-gap: 1rem;
      justify-content: flex-start;
      padding-Top: 10px;
      min-height: 200px;
      padding: 10px;
      // margin-bottom: 10px;            
    }           
`;

const InnerBodyTextDiv = styled.div` 
    display: flex;
    align-self: center;  
    justify-content: center;    
    text-align: center;    
    column-gap: 4em;
    font-size: 28px;         
    font-weight: bold;
    // color: black;
    font-family: Laila Bold;
    color: #060fce;    
    fontSize: 2em; 
    border-radius: 30px;
    
    background-color: rgba(0,0,0,0);
    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;                
      width: 98%;
      font-size: .8em;
      column-gap: 1em;
      
    }           
`;
const InsideDiv = styled.div`
display: flex;
flex-direction: column;
position: relative;
top: 15px;
row-gap: 1rem;
overflow-y: auto;
@media screen and (max-width: 768px) {
    row-gap: .25rem;
}
`;


const StyledEnterAppButton = styled.button`    
    align-self: center;
    font-family: Laila Bold;
    color: white;
    border: 1px solid #060fce;
    border-radius: 90px;
    background-color: #060fce;    
    height: 25px;    
    font-size: 1em;    
    top: 5px;
    height: 2.5em;
    width: 8.5em;
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 768px) {
        
    }
`;

const HeaderTextImage = styled.img`
    align-self: center;
    width: 50%;

`;

const HeroTextDiv = styled.div`
    width: 50%;
     @media screen and (max-width: 768px) {
        width: 100%;
        padding: 10px;   
    }
`;


const MainPage =() => {
    const navigate = useNavigate();

    const onEnterAppClick = () => {
        navigate("/nft");
    }

    return(        
       <PageWrapperDiv>
        <div style={{display: "flex", flexDirection: "column", width: "100%",}}>            
            <InsideDiv>
                <HeaderTextImage src={headerText} alt="" />
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%",columnGap: "2rem", }}>
                        <div><a href="https://twitter.com/SapphiregardIO" target="_blank" rel="noreferrer" ><img style={{height: "25px"}} src={xLogo} alt=""/></a></div>
                        <div><a href="https://t.me/sapphiregardenio" target="_blank" rel="noreferrer"><img style={{height: "35px"}} src={tgLogo} alt="" /></a></div>
                    </div>
                <BodyTextDiv >
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{paddingBottom: "8px"}}>Gnomey minting IS LIVE</div>
                        <div style={{paddingBottom: "8px"}}>Go into the App to Mint yours now!!</div>
                        {/* <div style={{paddingBottom: "8px"}}>First $ORCHID Seed airdrop in:</div>
                        <FlipClockCountdown renderMap={[true,true,true,true]} digitBlockStyle={{width: "20px", height: "50px", fontSize: "1em"}}  separatorStyle={{color: "black"}} showLabels={false} labelStyle={{color: "black"}} to={"May 1, 2024 00:00 UTC"} />                   */}
                    </div>
                    
                    <InnerBodyTextDiv>
                        <HeroTextDiv>
                            Welcome to Sapphire Garden, where your Gnomeys handle all the heavy lifting! Mint your Gnomey NFT now and let the planting begin. Get ready to sow the Seeds for juicy yields!
                        </HeroTextDiv>
                        <StyledEnterAppButton  onClick={onEnterAppClick}>
                            Enter App
                        </StyledEnterAppButton>
                    </InnerBodyTextDiv>
                </BodyTextDiv>
                
            </InsideDiv>
            
            <DisclaimerText />
        </div>
       </PageWrapperDiv>
       
        
    )
}

export default MainPage;