import { createSlice } from '@reduxjs/toolkit';

const userMessageStateSlice = createSlice({
    name: 'userMessage',
    initialState: {show: false, message: ""},
    reducers: {
        setUserMessage(state,action){
            state.show = action.payload.show;
            state.message = action.payload.message;
        },     
    }
});

export const UserMessageStateActions = userMessageStateSlice.actions;

export default userMessageStateSlice.reducer;
