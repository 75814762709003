import lilyLogo from "./lilly_token.png";
import orchidLogo from "./orchid_token.png";
import tulipLogo from "./tulip_token.png";
import orchidRose from "./orchid_rose.png"
import tulipRose from "./tulip_rose.png";
import lilyRose from "./lily_rose.png";
import rosyLogo from './rosy.webp';
import yourProject from './your_project_question.png';

export const TokenLogos = {
 LillyLogo: lilyLogo,
 OrchidLogo: orchidLogo,
 TulipLogo: tulipLogo,
 OrchidRose: orchidRose,
 TulipRose: tulipRose,
 LilyRose: lilyRose,
 RosyRewards: rosyLogo,
 YourProjectHere: yourProject,

}  