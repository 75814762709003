import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { SideDrawerStateActions} from '../../store/state/sidedrawerstate';
import authstate from '../../store/state/authstate';
import './SideDrawer.css'
import * as NavBarGUI from './components';
import { ClickableImage, MenuButtonDiv } from '../../components';
import hdr from '../../assets/header2.png'
import { useNavigate } from 'react-router';
import WalletButton from '../../components/wallet';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';


const MainNavWrapperDiv = styled.div`
  display: flex;
  min-width: 100%;
  margin: 5px;
  align-items: center;
  @media screen and (max-width: 768px) { 
    
  }
`;

const ButtonsDivWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 50px;
  @media screen and (max-width: 768px) { 
    flex-direction: column; 
    margin: 0px;
    align-items: center;   
  }
`;
const SideDrawer = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobileView = useMediaQuery('(max-width:768px)');
  const [drawerClass, setDrawerClass] = useState('side-drawer');
  const sideDrawerOpen = useSelector(state => state.sideDrawerState.showSideDrawer );    
  

  useEffect(() => {
    
    const Init = async () => {
        if(authstate){
          
        } 
      }
      Init();
      
    if (sideDrawerOpen) {     
        setDrawerClass('side-drawer open');      
    }
    else{
      setDrawerClass('side-drawer');
    }

  }, [sideDrawerOpen])

  
  const closeDrawer = () => {
    dispatch(SideDrawerStateActions.setBackDropState(false));
    dispatch(SideDrawerStateActions.setSideDrawerOpen(false));   
  }

  const onMenuClick = (page) =>{
    if(page === "NFT"){
      navigate("/nft");
    }else if(page === "SEEDS"){
      navigate("/seeds/orchids");
    }else if(page === "GARDEN"){
      navigate("/garden");
    }else if(page === "HOME"){
      navigate("/");
    }    
   
  }
  
  return (
    <NavBarGUI.SideDrawerNavDiv onClick={closeDrawer} className={drawerClass}>
          {mobileView === true && <div>  
           <div style={{display: "flex", top: "30px", justifyContent: "center"}}>
              <ClickableImage src={hdr} alt="header" style={{width: "85%"}}   onClick={() => onMenuClick("HOME")}/>
          </div>               
            <ButtonsDivWrapper>
              <MenuButtonDiv onClick={() => onMenuClick("NFT")}>NFT</MenuButtonDiv>
              <MenuButtonDiv onClick={() => onMenuClick("SEEDS")}>Seeds</MenuButtonDiv>
              <MenuButtonDiv onClick={() => onMenuClick("GARDEN")}>Garden</MenuButtonDiv>          
              <WalletButton />       
            </ButtonsDivWrapper>      
          </div>
      }
      </NavBarGUI.SideDrawerNavDiv>
  )
}

export default SideDrawer