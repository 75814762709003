import styled from "styled-components";

export  const SeedsWrapperDiv = styled.div`
    display: flex;    
    justify-content: center;
    column-gap: 2rem;
    position: relative;
    top: 10px;
    height: 500px;
    width: 80%;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      column-gap: 1rem;
      padding: 20px;
    }
      @media screen and (max-width: 325px) {
        top: 85px;
        
      }
`


export const TokenomicsWrapperDiv = styled.div`
  width: 50%;
  padding: 10px;
  border: 2px solid #666666;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-weight: bolder;
  
  @media screen and (max-width: 768px) {
    width: 100%;    
    padding: 0px;
    
  }
`;