import styled from 'styled-components';
import './App.css';
import { Outlet } from 'react-router';
import NavHeader from './pages/nav';
import { useDispatch, useSelector } from 'react-redux';
import { SideDrawerStateActions } from './store/state/sidedrawerstate';
import SideDrawer from './pages/nav/sidedrawer';
import ModalBackDrop from './modals/backdrop';
import { useState } from 'react';
import InfoMessageModal from './modals/infomsgmodal';
import DisclaimerText from './components/disclaimer';

const MainWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;  
  height: 100vh; 
  overflow: hidden;
  
  @media screen and (max-width: 768px) { 
    
    
  }
`;



function App() {
  const dispatch = useDispatch(); 
  const userMessage = useSelector(state => state.userMessageReduxState);
  
  const showBackDrop = useSelector(state => state.sideDrawerState.showbackDrop );
  const sideDrawerOpen = useSelector(state => state.sideDrawerState.showSideDrawer );


const drawerToggleClickHandler = () => {     
  dispatch(SideDrawerStateActions.setBackDropState(!showBackDrop));
  dispatch(SideDrawerStateActions.setSideDrawerOpen(!sideDrawerOpen));        
}

const backDropClickHandler = () => {
  dispatch(SideDrawerStateActions.setBackDropState(false));
  dispatch(SideDrawerStateActions.setSideDrawerOpen(false));
}


  return (
    <MainWrapperDiv>
       {
           showBackDrop &&  <ModalBackDrop backDropClick={backDropClickHandler} />
        }
         {
            userMessage.show && <InfoMessageModal  />
          }   
      {/* <NavHeader  drawerClickHandler={drawerToggleClickHandler} />
      <SideDrawer   show={sideDrawerOpen} />           */}
      <div style={{display: "flex", justifyContent: "center",width: "100%", height: "100%", }} >
            <Outlet  />
        </div>
      
                    
    </MainWrapperDiv>
  );
}

export default App;
