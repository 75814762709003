import FarmCard from "../components/farm";
import styled from "styled-components";
import DisclaimerText from "../components/disclaimer";
import AppMenu from "../components/menu";
import bgImg from '../assets/app_bg.png';


const PageWrapperDiv = styled.div` 
    display: flex;    
    justify-content: center;
    align-content: center;    
    width: 100%;    
    background-image:  url(${bgImg});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) {      
        margin: 0px;
        width: 100%;        
        
        // margin-top: 85px;        
    }
`;

const GardenWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 844px) {  
       
    }
    @media screen and (max-width: 768px) {                          
        width: 95%;  
              
    }
`;

const BodyTextDiv = styled.div`  
    align-self: center;  
    text-align: center;    
    font-size: 28px;         
    font-weight: bold;
    // color: black;
    font-family: Laila Bold;
    color: #060fce;
    position: relative;    
    fontSize: 2em;
    width: 75%;   
    position: relative;
    top: 35px;
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 1.3em;
      // margin-bottom: 10px;      
      top: 30px;
    }           
`;

const GardenPage = () => {

    return(
        <PageWrapperDiv>
            <GardenWrapperDiv>
                <AppMenu />
                <BodyTextDiv>The Garden is where the $BEEs farm to help to fertilize and grow the garden.</BodyTextDiv>                
                <BodyTextDiv>Coming soon...</BodyTextDiv>                
                {/* <FarmCardWrapperDiv>
                    <FarmCard lpToken={"ORCHID-ROSE"} />
                    <FarmCard lpToken={"LILY-ROSE"} />                    
                </FarmCardWrapperDiv> */}
                
            </GardenWrapperDiv>
            
        </PageWrapperDiv>
    )
}

export default  GardenPage;