import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, Routes } from 'react-router';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import store from './store/index';
import MainPage from './pages';
import NFTPage from './pages/nftpage';
import SeedsPage from './pages/seedspage';
import GardenPage from './pages/gardenpage';
import DocsPage from './pages/docs';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>     
      <HashRouter> 
        <Routes>
            <Route exact path="/" element={<App />}>
              <Route exact path="/" element={<MainPage />} />
              <Route exact path="/nft" element={<NFTPage />} />
              <Route exact path="/seeds/:seedName" element={<SeedsPage />} />
              <Route exact path="/garden" element={<GardenPage />} />
              <Route exact path="/docs" element={<DocsPage />} />
            </Route>
          </Routes>        
      </HashRouter>      
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
