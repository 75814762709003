import { LabelValuePairDiv,  TokenMenuImg } from "../components";
import {TokenLogos} from "../assets";
import { useState } from "react";
import { SeedsWrapperDiv } from "./components";
import styled from "styled-components";


const SectionDiv = styled.section`
    display: flex;
    flex-direction: column;    
    align-items: center;
    row-gap: 1rem;
    width: 50%;
    background: linear-gradient(to bottom, #87ceeb,  #A4DCF3);
    color:  #060fce;
    border: 1px solid #060fce;;
    border-radius: 45px;
    min-height: 345px;
    padding: 18px;
    font-family: Laila Bold;
    font-size: 1.5em;
    position: relative;
    
    
    @media screen and (max-width: 768px) {
        width: 90%;
        padding: 3px;
        top: 55px;
        row-gap: 0.5em; 
        font-size: 1em;   
    }
`;


const SectionTextDiv = styled.section`
    display: flex;
    flex-direction: column;    
    align-items: center;
    row-gap: 1rem;
    width: 50%;
    background: linear-gradient(to bottom, #87ceeb,  #A4DCF3);
    color:  #060fce;
    border: 1px solid #060fce;;
    border-radius: 45px;
    min-height: 365px;
    padding: 18px;
    font-family: Laila Bold;
    font-size: 1.5em;
    position: relative;
    
    
    @media screen and (max-width: 768px) {
        width: 90%;
        padding: 8px;
        padding-top: 15px;
        row-gap: 0.5em;
        font-size: 1em;
        top: 65px;        
        height: 500px;
        border-radius: 30px;
        min-height: 305px;
        
    }
`;

const TextDiv = styled.div`
font-family: Laila Bold;
@media screen and (max-width: 768px) {
    width: 100%;
    
}
`;

const SeedsLillies = () => {    
    const [userTokenStats, SetUserTokenStats] = useState({userTokenBalance: 0, userLPBalance: 0, userTotalYield: 0})
    const [burntSupply, SetBurntSupply] = useState(0);
    
     return(
        <SeedsWrapperDiv>   
           
           <SectionDiv> 
                <LabelValuePairDiv>
                    <TextDiv>Supply Burnt</TextDiv>
                    <TextDiv>{burntSupply}</TextDiv>
                </LabelValuePairDiv>
                <LabelValuePairDiv>
                    <TextDiv>Balance</TextDiv>
                    <TextDiv style={{display: "flex", alignItems: "center"}}><div>{userTokenStats.userTokenBalance}</div><TokenMenuImg style={{width: "20px", marginLeft: "5px"}}  src={TokenLogos.LillyLogo} alt="LILYs" /></TextDiv>
                </LabelValuePairDiv>
                <LabelValuePairDiv>
                    <TextDiv>LILY/? LP (2x):</TextDiv>
                    <TextDiv>{userTokenStats.userLPBalance} ILP</TextDiv>
                </LabelValuePairDiv>               
                <LabelValuePairDiv>
                    <TextDiv>Total Yield</TextDiv>
                    <TextDiv  style={{display: "flex", alignItems: "center"}}><div>{userTokenStats.userTotalYield}</div>
                    TBD
                    {/* <TokenMenuImg style={{width: "20px", marginLeft: "5px"}}  src={TokenLogos.RosyRewards} alt="LILYs" /> */}
                    </TextDiv>
                </LabelValuePairDiv>
           
            </SectionDiv>            
            <SectionTextDiv>
                
                <TextDiv>Hyper-deflationary token -- 0.5% burn on buy</TextDiv>
                <TextDiv>1.5% buy and 2.5% sell fees that creates yield in $?</TextDiv>
                <TextDiv>2x yield by holding LILY/? LP</TextDiv>
                <TextDiv>Contract ownership is renounced. Fully decentralized trustless yield protocol.</TextDiv>
                <div style={{display: "flex", flexDirection: "column",rowGap: "1rem", width: "100%"}}>
                    <TextDiv>Distribution:</TextDiv>
                    <TextDiv>{new Intl.NumberFormat().format(1000000000)} Total Supply</TextDiv>
                    <TextDiv>50% burned liquidity </TextDiv>                    
                    <TextDiv>45% Gnomey & partner air-drop</TextDiv>
                    <TextDiv>5% No expectations</TextDiv>
                </div>                
            </SectionTextDiv>
        </SeedsWrapperDiv>   
     )   
    }
       
   
   
   export default SeedsLillies;