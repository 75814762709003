import styled from "styled-components";

export const FarmCardDiv = styled.div`
   display: flex;
    flex-direction: column;
    justify-content: center;    
    background: linear-gradient(to bottom, #87ceeb,  #A4DCF3);
    color:  #060fce;
    border: 1px solid #060fce;;
    border-radius: 45px;
    height: 455px;    
    padding: 18px;
    font-family: Laila Bold;
    font-size: 1.5em;
    position: relative;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    
    
  @media screen and (max-width: 768px) {  
    min-width: 0px;
    margin: 0px;    
    margin-bottom: 10px;    
    width: 85%;
    align-content: center;
  }
`;

export const TitleRowDiv = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 8px; 
    @media screen and (max-width: 768px) {      
      padding: 0px;      
      
    }   
`;

export const DescriptionRowDiv = styled.div`
`;


export const TextDiv = styled.div`
font-family: Laila Bold;

`;

export const LabelsWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) { 
    flex-direction: row;
  }
`;