import { LabelValuePairDiv, TextDiv, TextLabelDiv, TextValueDiv, TokenMenuImg } from "../components";
import {TokenLogos} from "../assets";
import { useState } from "react";
import { SeedsWrapperDiv, TokenomicsWrapperDiv } from "./components";

const SeedsTulips = () => {
    const [userTokenStats, SetUserTokenStats] = useState({userTokenBalance: 0, userLPBalance: 0, userTotalYield: 0})
    const [burntSupply, SetBurntSupply] = useState(0);

 return(
    <SeedsWrapperDiv>   
    
            <TokenomicsWrapperDiv>                
                <LabelValuePairDiv>
                    <TextLabelDiv>Supply Burnt</TextLabelDiv>
                    <TextValueDiv>{burntSupply}</TextValueDiv>
                </LabelValuePairDiv>
                <LabelValuePairDiv>
                    <TextLabelDiv>Balance</TextLabelDiv>
                    <TextValueDiv><div>{userTokenStats.userTokenBalance}</div><TokenMenuImg style={{width: "20px", marginLeft: "5px"}}  src={TokenLogos.TulipLogo} alt="Lily" /></TextValueDiv>
                </LabelValuePairDiv>
                <LabelValuePairDiv>
                    <TextLabelDiv>TULIP/Rose LP (2x):</TextLabelDiv>
                    <TextValueDiv>{userTokenStats.userLPBalance} ILP</TextValueDiv>
                </LabelValuePairDiv>               
                <LabelValuePairDiv>
                    <TextLabelDiv>Total Yield</TextLabelDiv>
                    <TextValueDiv><div>{userTokenStats.userTotalYield}</div><TokenMenuImg style={{width: "20px", marginLeft: "5px"}}  src={TokenLogos.TulipLogo} alt="Lily" /></TextValueDiv>
                </LabelValuePairDiv>
            </TokenomicsWrapperDiv>
            <div style={{margin: "20px"}}></div>
            <TokenomicsWrapperDiv>
                <TextDiv>Tulips' iconic blooming bulbs are cherished for their vibrant hues adding elegance in gardens worldwide.</TextDiv>
                <div style={{marginTop: "30px"}}>
                    <TextDiv>TULIP is a hyper-deflationary token with a 0.5% burn on buy which yields $ROSE</TextDiv>
                </div>
                <div style={{marginTop: "30px"}}>
                    <TextDiv>1.5% buy and 2.5% sell fees that buy $ROSE and automatically puts into your wallet.</TextDiv>
                </div>
                <div style={{marginTop: "30px"}}>
                    <TextDiv>Double the yield by creating and holding TULIP/ROSE LP</TextDiv>
                </div>
                <div style={{marginTop: "30px"}}>
                    <TextDiv>Contract ownership is renounced and cannot be modified. Fully decentralized trustless yield protocol.</TextDiv>
                </div>
                <div style={{marginTop: "30px"}}>                
                    <TextDiv>Distribution:</TextDiv>
                    <TextDiv>{new Intl.NumberFormat().format(5000000)} Total Supply</TextDiv>
                    <TextDiv>50% burned liquidity (20% TULIP/ROSE, 10% TULIP/IX, 10% TULIP/LILY, 10% TULIP/ORCHID)</TextDiv>
                    <TextDiv>45% Sapphire Network air-drop</TextDiv>
                    <TextDiv>5% no expectations</TextDiv>
                </div>
            </TokenomicsWrapperDiv>        
    </SeedsWrapperDiv>
 )    
}
   
export default SeedsTulips;