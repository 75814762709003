import { ethers } from "ethers";
import Web3 from "web3";


export const sleep = (delay) => new Promise (( resolve) => setTimeout (resolve, delay));


export const AddUserGnomeysToMetaMask = (address, ids) =>{
    
    const calls = ids.map( (v,ix) => {
        return {
            method: "wallet_watchAsset",
            params: {
                type: 'ERC721',
                options:{
                    address: address,
                    tokenId: v
                }
            }
        }
    })
   window.ethereum.sendAsync(calls)
}
const GetGnomeyReadOnlyContract = (config) => {
    const httpWeb3 =  getWeb3ContractHttp(config,config.ABI.gnomeyNFT,config.addresses.gnomeyNFT);
    return httpWeb3;
}
export const GetConnectedContract =  (address, abi) => {
    const provider  = new ethers.providers.Web3Provider(window.ethereum,"any");
    const wodMatContract = new ethers.Contract(address,abi,provider);
    
    const connectedContract = wodMatContract.connect(provider.getSigner());
    return connectedContract;
}

export const GetWeb3Http = (config) => {
    
     const httpProvider = new Web3.providers.HttpProvider(config.rpc, { timeout: 60000 })
    const web3 = new Web3(httpProvider)
    return web3
}

export const getWeb3ContractHttp =  (config,abi, address) => {    
    let web3 =  GetWeb3Http(config)
    return new web3.eth.Contract(
          abi,
          address);    
 }


export const GetSaleStatus = async (config) => {
    
    const gnomeyContract = GetGnomeyReadOnlyContract(config);
    
    const isPublicSale = await gnomeyContract.methods._publicSalePhase().call();
    const isWhiteListSale = await gnomeyContract.methods._preSalePhase().call();
    
    return {isPublicSale, isWhiteListSale};
}

export const GetEtherBalanceForUser = async (address) => {
    const provider  = new ethers.providers.Web3Provider(window.ethereum,"any");
 const balance = await provider.getBalance(address)
 return balance;
}

export const GetWhiteListStatusForUser = async (config,address) => {
   
    const gnomeyContract = GetGnomeyReadOnlyContract(config);
    const result = await gnomeyContract.methods.isWhiteListed(address).call();
    return result;
}


export const GetTotalSupply = async (config) => {
   
    const gnomeyContract = GetGnomeyReadOnlyContract(config);
    const result = await gnomeyContract.methods.totalSupply().call();
    return result;
}

export const GetGnomeyMintPrice = async (config) => {
    const gnomeyContract = GetConnectedContract(config.addresses.gnomeyNFT,config.ABI.gnomeyNFT);
    const {isPublicSale, isWhiteListSale} = await GetSaleStatus(config);
    let price = 0;
    if(isPublicSale){
        price = await gnomeyContract._publicSaleMintPrice();
    }else if(isWhiteListSale){
        price = await gnomeyContract._preSaleMintPrice();
    }else{
        throw Error("price not available");
    }    
    return price;
}

export const MintGnomey = async (config, address) => {
    const gnomeyContract = GetConnectedContract(config.addresses.gnomeyNFT,config.ABI.gnomeyNFT);
    const price = await GetGnomeyMintPrice(config);

    const result = await gnomeyContract.buyGnomey({from: address,value: price.toString()});    
    const tx = await result.wait();
    return tx;

}

export const GetUsersGnomeys = async (config, address) => {
    const gnomeyContract = GetGnomeyReadOnlyContract(config);
    const result = await gnomeyContract.methods.getUserNftTokens(address).call();
    const data = [];
    for(let i = 0; i < result.length; i++){
        const tokenURI = await gnomeyContract.methods.tokenURI(result[i]).call();
        data.push({tokenId: result[i].toString(), metaDataURI: tokenURI});
    }    
    // console.log(data);
    return data;
}