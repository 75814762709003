
import styled from "styled-components";


export const SideDrawerNavDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;    
    
    height: 100vh;
    background-color: black;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;    
    padding-top: 10px;
    
    max-width: 400px;
    z-index: 200;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out;      

   
`;

