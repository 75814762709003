
export const GNOMEY_STATS =[
    {
        "trait_type": "Background",
        "values": {
            "Green": 112,
            "Black": 115,
            "Yellow": 111,
            "Green Spotlight": 52,
            "Pink": 98,
            "Pink Spotlight": 60,
            "Forest": 39,
            "Space": 31,
            "Black Spotlight": 45,
            "Picket Fence": 38,
            "Yellow Spotlight": 36
        }
    },
    {
        "trait_type": "Base",
        "values": {
            "White": 265,
            "Silver": 175,
            "Brown": 264,
            "Green Beaten": 17,
            "Gold": 16
        }
    },
    {
        "trait_type": "Boots",
        "values": {
            "Dirty Black": 309,
            "Sporty White": 123,
            "Black": 234,
            "Gold": 71
        }
    },
    {
        "trait_type": "beard",
        "values": {
            "Blue": 91,
            "Orange": 101,
            "Green": 86,
            "Black": 136,
            "Grey": 102,
            "Yellow": 79,
            "Red": 74,
            "White": 57,
            "Brown": 11
        }
    },
    {
        "trait_type": "Clothing",
        "values": {
            "Yellow": 47,
            "Red": 113,
            "Purple": 101,
            "Yellow Oasis Network": 58,
            "Red Oasis Network": 93,
            "Gold": 38,
            "Blue": 89,
            "Pink": 67,
            "Green": 96,
            "Formal Tie": 10,
            "Live Punk": 9,
            "Sporty": 5,
            "Buttons Yellow": 10,
            "Magician": 1
        }
    },
    {
        "trait_type": "Mouth",
        "values": {
            "Surprised": 96,
            "Grin": 443,
            "Tongue": 101,
            "Gold": 47,
            "Smoke": 50
        }
    },
    {
        "trait_type": "Hats",
        "values": {
            "Pink Rosy Patch": 46,
            "Purple": 87,
            "Red Stetson": 47,
            "Red": 88,
            "Red Rosy Patch": 42,
            "Pink": 81,
            "Purple Rosy Patch": 41,
            "Magician": 31,
            "Orange": 85,
            "Yellow": 99,
            "Horns": 3,
            "Orange Rosy Patch": 42,
            "Brown Stetson": 45
        }
    },
    {
        "trait_type": "In hand",
        "values": {
            "Empty": 494,
            "Gold Shovel": 23,
            "Shovel": 148,
            "Rose": 13,
            "Pick": 47,
            "Staff": 12
        }
    },
    {
        "trait_type": "Eyes",
        "values": {
            "Confused": 371,
            "Fire": 36,
            "Love": 93,
            "Normal": 162,
            "Laser": 14,
            "Wink Wink": 37,
            "Sunglasses": 24
        }
    }
]

const GNOMEY_RANK = [
    {
        "id": 70,
        "score": 314.39
    },
    {
        "id": 611,
        "score": 310.18
    },
    {
        "id": 401,
        "score": 309.09000000000003
    },
    {
        "id": 532,
        "score": 308.96999999999997
    },
    {
        "id": 41,
        "score": 308.82
    },
    {
        "id": 264,
        "score": 307.59999999999997
    },
    {
        "id": 675,
        "score": 307.19
    },
    {
        "id": 24,
        "score": 306.52
    },
    {
        "id": 67,
        "score": 305.3
    },
    {
        "id": 663,
        "score": 304.34
    },
    {
        "id": 498,
        "score": 303.92999999999995
    },
    {
        "id": 384,
        "score": 303.8
    },
    {
        "id": 340,
        "score": 303.53000000000003
    },
    {
        "id": 506,
        "score": 303.53000000000003
    },
    {
        "id": 445,
        "score": 303.53
    },
    {
        "id": 709,
        "score": 302.99000000000007
    },
    {
        "id": 272,
        "score": 302.86
    },
    {
        "id": 617,
        "score": 302.31
    },
    {
        "id": 372,
        "score": 302.17
    },
    {
        "id": 336,
        "score": 301.76
    },
    {
        "id": 480,
        "score": 301.62
    },
    {
        "id": 665,
        "score": 301.22
    },
    {
        "id": 39,
        "score": 301.1
    },
    {
        "id": 191,
        "score": 300.95
    },
    {
        "id": 380,
        "score": 299.72
    },
    {
        "id": 376,
        "score": 299.46999999999997
    },
    {
        "id": 549,
        "score": 299.18
    },
    {
        "id": 29,
        "score": 298.66
    },
    {
        "id": 34,
        "score": 298.51
    },
    {
        "id": 122,
        "score": 297.96
    },
    {
        "id": 291,
        "score": 297.84
    },
    {
        "id": 430,
        "score": 297.69
    },
    {
        "id": 403,
        "score": 297.57
    },
    {
        "id": 274,
        "score": 296.76
    },
    {
        "id": 153,
        "score": 296.62
    },
    {
        "id": 661,
        "score": 296.34000000000003
    },
    {
        "id": 373,
        "score": 296.06
    },
    {
        "id": 523,
        "score": 295.93
    },
    {
        "id": 130,
        "score": 295.8
    },
    {
        "id": 648,
        "score": 295.8
    },
    {
        "id": 112,
        "score": 295.4
    },
    {
        "id": 310,
        "score": 295.12
    },
    {
        "id": 726,
        "score": 295.12
    },
    {
        "id": 711,
        "score": 294.71000000000004
    },
    {
        "id": 181,
        "score": 294.58
    },
    {
        "id": 593,
        "score": 294.03999999999996
    },
    {
        "id": 343,
        "score": 293.77
    },
    {
        "id": 36,
        "score": 293.76
    },
    {
        "id": 466,
        "score": 293.35
    },
    {
        "id": 623,
        "score": 293.22
    },
    {
        "id": 313,
        "score": 292.96
    },
    {
        "id": 113,
        "score": 292.94
    },
    {
        "id": 414,
        "score": 292.94
    },
    {
        "id": 598,
        "score": 291.73
    },
    {
        "id": 158,
        "score": 290.78999999999996
    },
    {
        "id": 555,
        "score": 290.64
    },
    {
        "id": 200,
        "score": 290.5
    },
    {
        "id": 544,
        "score": 290.38
    },
    {
        "id": 666,
        "score": 290.36
    },
    {
        "id": 615,
        "score": 289.82
    },
    {
        "id": 228,
        "score": 289.69
    },
    {
        "id": 599,
        "score": 289.55999999999995
    },
    {
        "id": 361,
        "score": 289.28999999999996
    },
    {
        "id": 256,
        "score": 288.61999999999995
    },
    {
        "id": 411,
        "score": 288.07000000000005
    },
    {
        "id": 415,
        "score": 288.07
    },
    {
        "id": 514,
        "score": 287.66
    },
    {
        "id": 470,
        "score": 287.65
    },
    {
        "id": 224,
        "score": 287.25
    },
    {
        "id": 18,
        "score": 287.12
    },
    {
        "id": 594,
        "score": 286.84000000000003
    },
    {
        "id": 388,
        "score": 286.7
    },
    {
        "id": 56,
        "score": 286.56
    },
    {
        "id": 672,
        "score": 286.44
    },
    {
        "id": 681,
        "score": 286.29
    },
    {
        "id": 677,
        "score": 286.15
    },
    {
        "id": 641,
        "score": 286.04
    },
    {
        "id": 603,
        "score": 285.88
    },
    {
        "id": 151,
        "score": 285.34999999999997
    },
    {
        "id": 389,
        "score": 285.08000000000004
    },
    {
        "id": 642,
        "score": 284.94000000000005
    },
    {
        "id": 613,
        "score": 284.65999999999997
    },
    {
        "id": 262,
        "score": 284.54
    },
    {
        "id": 515,
        "score": 284.40000000000003
    },
    {
        "id": 567,
        "score": 284.27000000000004
    },
    {
        "id": 358,
        "score": 284.13000000000005
    },
    {
        "id": 432,
        "score": 283.86
    },
    {
        "id": 341,
        "score": 283.57
    },
    {
        "id": 538,
        "score": 283.46
    },
    {
        "id": 607,
        "score": 283.31
    },
    {
        "id": 205,
        "score": 282.9
    },
    {
        "id": 207,
        "score": 282.09
    },
    {
        "id": 671,
        "score": 282.09
    },
    {
        "id": 406,
        "score": 281.96
    },
    {
        "id": 705,
        "score": 281.96
    },
    {
        "id": 278,
        "score": 281.83000000000004
    },
    {
        "id": 685,
        "score": 281.7
    },
    {
        "id": 178,
        "score": 281.56
    },
    {
        "id": 289,
        "score": 281.56
    },
    {
        "id": 586,
        "score": 281.01000000000005
    },
    {
        "id": 520,
        "score": 280.06
    },
    {
        "id": 558,
        "score": 279.78
    },
    {
        "id": 597,
        "score": 279.78
    },
    {
        "id": 524,
        "score": 279.65
    },
    {
        "id": 133,
        "score": 279.25
    },
    {
        "id": 235,
        "score": 279.25
    },
    {
        "id": 518,
        "score": 279.25
    },
    {
        "id": 453,
        "score": 279.24
    },
    {
        "id": 306,
        "score": 278.98
    },
    {
        "id": 111,
        "score": 278.83
    },
    {
        "id": 9,
        "score": 277.88
    },
    {
        "id": 290,
        "score": 277.75
    },
    {
        "id": 242,
        "score": 277.34
    },
    {
        "id": 302,
        "score": 276.94000000000005
    },
    {
        "id": 326,
        "score": 276.94
    },
    {
        "id": 692,
        "score": 276.26
    },
    {
        "id": 288,
        "score": 275.85
    },
    {
        "id": 238,
        "score": 275.84999999999997
    },
    {
        "id": 54,
        "score": 275.44000000000005
    },
    {
        "id": 448,
        "score": 274.64
    },
    {
        "id": 385,
        "score": 274.37
    },
    {
        "id": 109,
        "score": 274.08
    },
    {
        "id": 316,
        "score": 274.08
    },
    {
        "id": 386,
        "score": 273.95
    },
    {
        "id": 631,
        "score": 273.81
    },
    {
        "id": 26,
        "score": 273.67999999999995
    },
    {
        "id": 60,
        "score": 273.67
    },
    {
        "id": 187,
        "score": 273.55
    },
    {
        "id": 440,
        "score": 273
    },
    {
        "id": 23,
        "score": 272.87
    },
    {
        "id": 98,
        "score": 272.74
    },
    {
        "id": 645,
        "score": 272.59
    },
    {
        "id": 637,
        "score": 272.33000000000004
    },
    {
        "id": 501,
        "score": 272.18
    },
    {
        "id": 565,
        "score": 271.92
    },
    {
        "id": 317,
        "score": 271.11
    },
    {
        "id": 439,
        "score": 271.1
    },
    {
        "id": 662,
        "score": 271.1
    },
    {
        "id": 148,
        "score": 271.09999999999997
    },
    {
        "id": 551,
        "score": 270.83000000000004
    },
    {
        "id": 627,
        "score": 270.83000000000004
    },
    {
        "id": 128,
        "score": 270.55
    },
    {
        "id": 303,
        "score": 270.43
    },
    {
        "id": 322,
        "score": 270.28
    },
    {
        "id": 633,
        "score": 270.16
    },
    {
        "id": 334,
        "score": 270.03
    },
    {
        "id": 421,
        "score": 269.74
    },
    {
        "id": 502,
        "score": 269.47
    },
    {
        "id": 333,
        "score": 269.46999999999997
    },
    {
        "id": 556,
        "score": 268.93
    },
    {
        "id": 382,
        "score": 268.8
    },
    {
        "id": 217,
        "score": 268.78999999999996
    },
    {
        "id": 137,
        "score": 268.67
    },
    {
        "id": 724,
        "score": 268.12
    },
    {
        "id": 3,
        "score": 267.71
    },
    {
        "id": 44,
        "score": 267.7
    },
    {
        "id": 167,
        "score": 267.58
    },
    {
        "id": 96,
        "score": 267.44
    },
    {
        "id": 139,
        "score": 267.03
    },
    {
        "id": 183,
        "score": 267.03
    },
    {
        "id": 377,
        "score": 266.77000000000004
    },
    {
        "id": 252,
        "score": 266.76
    },
    {
        "id": 30,
        "score": 266.49
    },
    {
        "id": 99,
        "score": 266.49
    },
    {
        "id": 190,
        "score": 266.36
    },
    {
        "id": 576,
        "score": 265.95
    },
    {
        "id": 314,
        "score": 265.81
    },
    {
        "id": 169,
        "score": 265.8
    },
    {
        "id": 529,
        "score": 265.14000000000004
    },
    {
        "id": 59,
        "score": 264.6
    },
    {
        "id": 166,
        "score": 264.58
    },
    {
        "id": 346,
        "score": 264.45
    },
    {
        "id": 404,
        "score": 264.32
    },
    {
        "id": 74,
        "score": 264.18
    },
    {
        "id": 253,
        "score": 263.78000000000003
    },
    {
        "id": 462,
        "score": 263.37
    },
    {
        "id": 142,
        "score": 263.23
    },
    {
        "id": 381,
        "score": 262.96
    },
    {
        "id": 572,
        "score": 262.83000000000004
    },
    {
        "id": 214,
        "score": 262.83
    },
    {
        "id": 729,
        "score": 262.80999999999995
    },
    {
        "id": 271,
        "score": 262.42
    },
    {
        "id": 475,
        "score": 262.28
    },
    {
        "id": 186,
        "score": 261.88
    },
    {
        "id": 574,
        "score": 261.88
    },
    {
        "id": 77,
        "score": 261.87
    },
    {
        "id": 625,
        "score": 261.73
    },
    {
        "id": 174,
        "score": 261.47
    },
    {
        "id": 81,
        "score": 261.33
    },
    {
        "id": 461,
        "score": 261.07
    },
    {
        "id": 227,
        "score": 260.93
    },
    {
        "id": 569,
        "score": 260.92
    },
    {
        "id": 236,
        "score": 260.79
    },
    {
        "id": 295,
        "score": 260.79
    },
    {
        "id": 540,
        "score": 260.79
    },
    {
        "id": 698,
        "score": 260.64
    },
    {
        "id": 162,
        "score": 260.51
    },
    {
        "id": 393,
        "score": 260.25
    },
    {
        "id": 413,
        "score": 260.11
    },
    {
        "id": 287,
        "score": 259.31000000000006
    },
    {
        "id": 734,
        "score": 258.9
    },
    {
        "id": 218,
        "score": 258.88
    },
    {
        "id": 349,
        "score": 258.35
    },
    {
        "id": 557,
        "score": 258.35
    },
    {
        "id": 656,
        "score": 257.81
    },
    {
        "id": 674,
        "score": 257.54
    },
    {
        "id": 391,
        "score": 257.53
    },
    {
        "id": 536,
        "score": 257.53
    },
    {
        "id": 390,
        "score": 257.4
    },
    {
        "id": 566,
        "score": 257.27
    },
    {
        "id": 212,
        "score": 257.26
    },
    {
        "id": 152,
        "score": 257.13
    },
    {
        "id": 150,
        "score": 257.12
    },
    {
        "id": 280,
        "score": 257.12
    },
    {
        "id": 354,
        "score": 256.98
    },
    {
        "id": 647,
        "score": 256.97999999999996
    },
    {
        "id": 486,
        "score": 256.59000000000003
    },
    {
        "id": 590,
        "score": 256.59
    },
    {
        "id": 400,
        "score": 256.58
    },
    {
        "id": 571,
        "score": 256.58
    },
    {
        "id": 455,
        "score": 256.45
    },
    {
        "id": 649,
        "score": 256.44
    },
    {
        "id": 435,
        "score": 256.31
    },
    {
        "id": 115,
        "score": 255.91000000000003
    },
    {
        "id": 37,
        "score": 255.77
    },
    {
        "id": 225,
        "score": 255.76999999999998
    },
    {
        "id": 309,
        "score": 255.5
    },
    {
        "id": 8,
        "score": 255.49
    },
    {
        "id": 135,
        "score": 255.23
    },
    {
        "id": 277,
        "score": 255.23
    },
    {
        "id": 507,
        "score": 255.09000000000003
    },
    {
        "id": 396,
        "score": 255.09
    },
    {
        "id": 500,
        "score": 254.68
    },
    {
        "id": 420,
        "score": 254.42000000000002
    },
    {
        "id": 398,
        "score": 254.41000000000003
    },
    {
        "id": 171,
        "score": 254.41
    },
    {
        "id": 250,
        "score": 254.28
    },
    {
        "id": 616,
        "score": 254.14000000000001
    },
    {
        "id": 577,
        "score": 254.14
    },
    {
        "id": 719,
        "score": 254.01
    },
    {
        "id": 329,
        "score": 253.88000000000002
    },
    {
        "id": 725,
        "score": 253.74
    },
    {
        "id": 344,
        "score": 253.59
    },
    {
        "id": 370,
        "score": 253.33
    },
    {
        "id": 658,
        "score": 253.06
    },
    {
        "id": 106,
        "score": 252.66000000000003
    },
    {
        "id": 328,
        "score": 252.64999999999998
    },
    {
        "id": 688,
        "score": 252.37
    },
    {
        "id": 687,
        "score": 252.10000000000002
    },
    {
        "id": 575,
        "score": 251.97
    },
    {
        "id": 397,
        "score": 251.84
    },
    {
        "id": 258,
        "score": 251.56
    },
    {
        "id": 699,
        "score": 251.43
    },
    {
        "id": 608,
        "score": 251.31000000000003
    },
    {
        "id": 402,
        "score": 251.3
    },
    {
        "id": 727,
        "score": 251.29000000000002
    },
    {
        "id": 660,
        "score": 251.14999999999998
    },
    {
        "id": 251,
        "score": 251.04000000000002
    },
    {
        "id": 504,
        "score": 250.62
    },
    {
        "id": 584,
        "score": 250.47999999999996
    },
    {
        "id": 254,
        "score": 250.33
    },
    {
        "id": 116,
        "score": 250.21
    },
    {
        "id": 149,
        "score": 250.21
    },
    {
        "id": 19,
        "score": 250.21
    },
    {
        "id": 659,
        "score": 249.94000000000003
    },
    {
        "id": 460,
        "score": 249.8
    },
    {
        "id": 63,
        "score": 249.65999999999997
    },
    {
        "id": 684,
        "score": 249.51999999999998
    },
    {
        "id": 441,
        "score": 249.39999999999998
    },
    {
        "id": 301,
        "score": 249.39000000000001
    },
    {
        "id": 35,
        "score": 249.39000000000001
    },
    {
        "id": 121,
        "score": 249.38000000000002
    },
    {
        "id": 476,
        "score": 249.25
    },
    {
        "id": 417,
        "score": 248.71
    },
    {
        "id": 69,
        "score": 248.58999999999997
    },
    {
        "id": 1,
        "score": 248.46
    },
    {
        "id": 695,
        "score": 248.44
    },
    {
        "id": 31,
        "score": 248.31
    },
    {
        "id": 618,
        "score": 248.17000000000002
    },
    {
        "id": 728,
        "score": 248.04
    },
    {
        "id": 80,
        "score": 247.50000000000003
    },
    {
        "id": 284,
        "score": 247.35000000000002
    },
    {
        "id": 452,
        "score": 247.22000000000003
    },
    {
        "id": 710,
        "score": 247.22000000000003
    },
    {
        "id": 234,
        "score": 247.21999999999997
    },
    {
        "id": 16,
        "score": 247.07999999999998
    },
    {
        "id": 667,
        "score": 246.83
    },
    {
        "id": 612,
        "score": 246.82000000000002
    },
    {
        "id": 101,
        "score": 246.8
    },
    {
        "id": 195,
        "score": 246.68
    },
    {
        "id": 215,
        "score": 246.68
    },
    {
        "id": 640,
        "score": 246.67000000000002
    },
    {
        "id": 72,
        "score": 246.55
    },
    {
        "id": 76,
        "score": 246.01000000000002
    },
    {
        "id": 552,
        "score": 245.73
    },
    {
        "id": 21,
        "score": 245.6
    },
    {
        "id": 168,
        "score": 245.59
    },
    {
        "id": 27,
        "score": 245.33
    },
    {
        "id": 550,
        "score": 245.19
    },
    {
        "id": 325,
        "score": 245.06
    },
    {
        "id": 585,
        "score": 244.92000000000002
    },
    {
        "id": 285,
        "score": 244.5
    },
    {
        "id": 424,
        "score": 244.49999999999997
    },
    {
        "id": 527,
        "score": 244.23
    },
    {
        "id": 624,
        "score": 244.1
    },
    {
        "id": 248,
        "score": 243.7
    },
    {
        "id": 650,
        "score": 243.69
    },
    {
        "id": 678,
        "score": 243.42000000000002
    },
    {
        "id": 275,
        "score": 243.42
    },
    {
        "id": 259,
        "score": 243.29000000000002
    },
    {
        "id": 350,
        "score": 242.87
    },
    {
        "id": 374,
        "score": 242.87
    },
    {
        "id": 606,
        "score": 242.75
    },
    {
        "id": 568,
        "score": 242.6
    },
    {
        "id": 209,
        "score": 242.47
    },
    {
        "id": 395,
        "score": 242.45999999999998
    },
    {
        "id": 105,
        "score": 242.34
    },
    {
        "id": 223,
        "score": 242.07
    },
    {
        "id": 605,
        "score": 242.07
    },
    {
        "id": 199,
        "score": 242.06
    },
    {
        "id": 298,
        "score": 241.8
    },
    {
        "id": 723,
        "score": 241.8
    },
    {
        "id": 348,
        "score": 241.78
    },
    {
        "id": 11,
        "score": 240.98000000000002
    },
    {
        "id": 444,
        "score": 240.71
    },
    {
        "id": 57,
        "score": 240.57000000000002
    },
    {
        "id": 521,
        "score": 240.44
    },
    {
        "id": 465,
        "score": 240.31
    },
    {
        "id": 714,
        "score": 240.17000000000002
    },
    {
        "id": 634,
        "score": 240.17
    },
    {
        "id": 189,
        "score": 239.9
    },
    {
        "id": 210,
        "score": 239.9
    },
    {
        "id": 407,
        "score": 239.48
    },
    {
        "id": 595,
        "score": 239.35000000000002
    },
    {
        "id": 97,
        "score": 239.07000000000002
    },
    {
        "id": 683,
        "score": 238.81
    },
    {
        "id": 553,
        "score": 238.67999999999998
    },
    {
        "id": 282,
        "score": 238.67000000000002
    },
    {
        "id": 315,
        "score": 238.40000000000003
    },
    {
        "id": 718,
        "score": 238.27
    },
    {
        "id": 203,
        "score": 238.13
    },
    {
        "id": 686,
        "score": 237.46
    },
    {
        "id": 15,
        "score": 237.32
    },
    {
        "id": 508,
        "score": 237.18
    },
    {
        "id": 578,
        "score": 237.05
    },
    {
        "id": 267,
        "score": 236.78000000000003
    },
    {
        "id": 104,
        "score": 236.77
    },
    {
        "id": 392,
        "score": 236.77
    },
    {
        "id": 643,
        "score": 236.65
    },
    {
        "id": 247,
        "score": 236.5
    },
    {
        "id": 40,
        "score": 236.24
    },
    {
        "id": 304,
        "score": 235.83
    },
    {
        "id": 204,
        "score": 235.81
    },
    {
        "id": 126,
        "score": 235.57
    },
    {
        "id": 628,
        "score": 235.55
    },
    {
        "id": 694,
        "score": 235.55
    },
    {
        "id": 579,
        "score": 234.74
    },
    {
        "id": 491,
        "score": 234.47
    },
    {
        "id": 673,
        "score": 234.46000000000004
    },
    {
        "id": 499,
        "score": 234.18
    },
    {
        "id": 531,
        "score": 233.66
    },
    {
        "id": 136,
        "score": 233.51000000000002
    },
    {
        "id": 362,
        "score": 233.25
    },
    {
        "id": 100,
        "score": 233.11
    },
    {
        "id": 450,
        "score": 233.11
    },
    {
        "id": 517,
        "score": 233.09999999999997
    },
    {
        "id": 481,
        "score": 232.44
    },
    {
        "id": 13,
        "score": 232.43
    },
    {
        "id": 230,
        "score": 232.41
    },
    {
        "id": 311,
        "score": 232.16
    },
    {
        "id": 419,
        "score": 232.01
    },
    {
        "id": 588,
        "score": 231.88
    },
    {
        "id": 467,
        "score": 231.76
    },
    {
        "id": 630,
        "score": 231.73999999999998
    },
    {
        "id": 490,
        "score": 231.21
    },
    {
        "id": 82,
        "score": 230.93
    },
    {
        "id": 160,
        "score": 230.66999999999996
    },
    {
        "id": 428,
        "score": 230.66000000000003
    },
    {
        "id": 636,
        "score": 230.66000000000003
    },
    {
        "id": 696,
        "score": 230.54000000000002
    },
    {
        "id": 516,
        "score": 230.53
    },
    {
        "id": 140,
        "score": 230.40000000000003
    },
    {
        "id": 503,
        "score": 230.4
    },
    {
        "id": 327,
        "score": 230.39
    },
    {
        "id": 720,
        "score": 230.13000000000002
    },
    {
        "id": 353,
        "score": 230.12
    },
    {
        "id": 511,
        "score": 229.98
    },
    {
        "id": 231,
        "score": 229.85
    },
    {
        "id": 93,
        "score": 229.84000000000003
    },
    {
        "id": 610,
        "score": 229.32
    },
    {
        "id": 269,
        "score": 229.04000000000002
    },
    {
        "id": 4,
        "score": 228.91
    },
    {
        "id": 730,
        "score": 228.91
    },
    {
        "id": 570,
        "score": 228.9
    },
    {
        "id": 449,
        "score": 228.77
    },
    {
        "id": 703,
        "score": 228.63000000000002
    },
    {
        "id": 68,
        "score": 228.36
    },
    {
        "id": 38,
        "score": 228.22000000000003
    },
    {
        "id": 42,
        "score": 228.22000000000003
    },
    {
        "id": 369,
        "score": 228.10000000000002
    },
    {
        "id": 197,
        "score": 227.81
    },
    {
        "id": 609,
        "score": 227.69
    },
    {
        "id": 244,
        "score": 227
    },
    {
        "id": 513,
        "score": 226.73000000000002
    },
    {
        "id": 276,
        "score": 226.32
    },
    {
        "id": 332,
        "score": 226.17
    },
    {
        "id": 220,
        "score": 226.05
    },
    {
        "id": 356,
        "score": 225.92
    },
    {
        "id": 563,
        "score": 225.79000000000002
    },
    {
        "id": 716,
        "score": 225.77
    },
    {
        "id": 654,
        "score": 225.51
    },
    {
        "id": 177,
        "score": 225.37
    },
    {
        "id": 43,
        "score": 224.98
    },
    {
        "id": 294,
        "score": 224.97
    },
    {
        "id": 429,
        "score": 224.83999999999997
    },
    {
        "id": 434,
        "score": 224.16000000000003
    },
    {
        "id": 194,
        "score": 224.03
    },
    {
        "id": 355,
        "score": 223.87
    },
    {
        "id": 286,
        "score": 223.2
    },
    {
        "id": 736,
        "score": 223.07
    },
    {
        "id": 145,
        "score": 223.06
    },
    {
        "id": 6,
        "score": 222.79999999999998
    },
    {
        "id": 265,
        "score": 222.79
    },
    {
        "id": 173,
        "score": 222.53
    },
    {
        "id": 351,
        "score": 222.39000000000004
    },
    {
        "id": 522,
        "score": 222.23999999999995
    },
    {
        "id": 123,
        "score": 222.12
    },
    {
        "id": 477,
        "score": 222.10999999999996
    },
    {
        "id": 463,
        "score": 221.85999999999999
    },
    {
        "id": 454,
        "score": 221.85000000000002
    },
    {
        "id": 363,
        "score": 221.85
    },
    {
        "id": 86,
        "score": 221.73000000000002
    },
    {
        "id": 281,
        "score": 221.58
    },
    {
        "id": 418,
        "score": 221.58
    },
    {
        "id": 652,
        "score": 221.44
    },
    {
        "id": 731,
        "score": 221.44
    },
    {
        "id": 196,
        "score": 221.18
    },
    {
        "id": 379,
        "score": 221.04000000000002
    },
    {
        "id": 176,
        "score": 220.64000000000001
    },
    {
        "id": 307,
        "score": 220.64
    },
    {
        "id": 560,
        "score": 220.62
    },
    {
        "id": 75,
        "score": 220.62
    },
    {
        "id": 635,
        "score": 220.49
    },
    {
        "id": 561,
        "score": 220.20999999999998
    },
    {
        "id": 261,
        "score": 219.81
    },
    {
        "id": 184,
        "score": 219.53999999999996
    },
    {
        "id": 479,
        "score": 219.41000000000003
    },
    {
        "id": 387,
        "score": 219.26999999999998
    },
    {
        "id": 533,
        "score": 219.26000000000002
    },
    {
        "id": 127,
        "score": 219.26
    },
    {
        "id": 12,
        "score": 218.98000000000002
    },
    {
        "id": 66,
        "score": 218.86
    },
    {
        "id": 52,
        "score": 218.72999999999996
    },
    {
        "id": 437,
        "score": 218.32000000000002
    },
    {
        "id": 299,
        "score": 218.32
    },
    {
        "id": 87,
        "score": 218.06
    },
    {
        "id": 182,
        "score": 217.79000000000002
    },
    {
        "id": 600,
        "score": 217.78
    },
    {
        "id": 541,
        "score": 217.36
    },
    {
        "id": 378,
        "score": 217.35999999999999
    },
    {
        "id": 474,
        "score": 216.83
    },
    {
        "id": 14,
        "score": 216.82999999999998
    },
    {
        "id": 457,
        "score": 216.82
    },
    {
        "id": 669,
        "score": 216.55
    },
    {
        "id": 85,
        "score": 216.42000000000002
    },
    {
        "id": 175,
        "score": 216.29000000000002
    },
    {
        "id": 124,
        "score": 215.88
    },
    {
        "id": 156,
        "score": 215.87
    },
    {
        "id": 95,
        "score": 215.87
    },
    {
        "id": 293,
        "score": 215.73000000000002
    },
    {
        "id": 292,
        "score": 215.48000000000002
    },
    {
        "id": 208,
        "score": 215.33
    },
    {
        "id": 64,
        "score": 215.32999999999998
    },
    {
        "id": 431,
        "score": 215.32
    },
    {
        "id": 737,
        "score": 215.06
    },
    {
        "id": 423,
        "score": 215.05
    },
    {
        "id": 360,
        "score": 214.92000000000002
    },
    {
        "id": 92,
        "score": 214.92000000000002
    },
    {
        "id": 433,
        "score": 214.79000000000002
    },
    {
        "id": 20,
        "score": 214.39
    },
    {
        "id": 583,
        "score": 213.98000000000002
    },
    {
        "id": 653,
        "score": 213.98000000000002
    },
    {
        "id": 484,
        "score": 213.96999999999997
    },
    {
        "id": 337,
        "score": 213.85000000000002
    },
    {
        "id": 58,
        "score": 213.85
    },
    {
        "id": 657,
        "score": 213.71
    },
    {
        "id": 233,
        "score": 213.3
    },
    {
        "id": 399,
        "score": 213.29999999999998
    },
    {
        "id": 221,
        "score": 212.76
    },
    {
        "id": 488,
        "score": 212.22
    },
    {
        "id": 193,
        "score": 212.20999999999998
    },
    {
        "id": 485,
        "score": 211.68
    },
    {
        "id": 368,
        "score": 211.53
    },
    {
        "id": 84,
        "score": 211.53
    },
    {
        "id": 50,
        "score": 211.41000000000003
    },
    {
        "id": 573,
        "score": 211.41000000000003
    },
    {
        "id": 62,
        "score": 211.41
    },
    {
        "id": 601,
        "score": 211.4
    },
    {
        "id": 157,
        "score": 210.32
    },
    {
        "id": 655,
        "score": 210.05
    },
    {
        "id": 249,
        "score": 209.77000000000004
    },
    {
        "id": 49,
        "score": 209.76
    },
    {
        "id": 211,
        "score": 209.51000000000002
    },
    {
        "id": 364,
        "score": 209.37
    },
    {
        "id": 129,
        "score": 209.23000000000002
    },
    {
        "id": 7,
        "score": 208.7
    },
    {
        "id": 103,
        "score": 208.68999999999997
    },
    {
        "id": 165,
        "score": 208.68
    },
    {
        "id": 347,
        "score": 208.54000000000002
    },
    {
        "id": 459,
        "score": 208
    },
    {
        "id": 138,
        "score": 207.87
    },
    {
        "id": 496,
        "score": 207.87
    },
    {
        "id": 545,
        "score": 207.60000000000002
    },
    {
        "id": 707,
        "score": 207.59000000000003
    },
    {
        "id": 416,
        "score": 207.46
    },
    {
        "id": 706,
        "score": 207.46
    },
    {
        "id": 246,
        "score": 207.45999999999998
    },
    {
        "id": 33,
        "score": 207.32
    },
    {
        "id": 456,
        "score": 207.06
    },
    {
        "id": 263,
        "score": 206.66000000000003
    },
    {
        "id": 534,
        "score": 206.53000000000003
    },
    {
        "id": 482,
        "score": 205.85
    },
    {
        "id": 2,
        "score": 205.56
    },
    {
        "id": 226,
        "score": 205.29000000000002
    },
    {
        "id": 219,
        "score": 205.28000000000003
    },
    {
        "id": 693,
        "score": 205.28000000000003
    },
    {
        "id": 331,
        "score": 205.02
    },
    {
        "id": 32,
        "score": 204.89000000000004
    },
    {
        "id": 443,
        "score": 204.89000000000001
    },
    {
        "id": 365,
        "score": 204.48000000000002
    },
    {
        "id": 494,
        "score": 203.94
    },
    {
        "id": 89,
        "score": 203.94
    },
    {
        "id": 632,
        "score": 203.53000000000003
    },
    {
        "id": 339,
        "score": 203.26
    },
    {
        "id": 582,
        "score": 203.26
    },
    {
        "id": 559,
        "score": 203.12
    },
    {
        "id": 589,
        "score": 203.12
    },
    {
        "id": 489,
        "score": 202.85000000000002
    },
    {
        "id": 146,
        "score": 202.84
    },
    {
        "id": 300,
        "score": 202.72
    },
    {
        "id": 670,
        "score": 202.59
    },
    {
        "id": 735,
        "score": 202.17000000000002
    },
    {
        "id": 697,
        "score": 201.76999999999998
    },
    {
        "id": 45,
        "score": 201.64
    },
    {
        "id": 700,
        "score": 201.5
    },
    {
        "id": 22,
        "score": 201.20999999999998
    },
    {
        "id": 154,
        "score": 200.82
    },
    {
        "id": 172,
        "score": 200.14
    },
    {
        "id": 722,
        "score": 200.01000000000002
    },
    {
        "id": 232,
        "score": 199.99
    },
    {
        "id": 239,
        "score": 199.87
    },
    {
        "id": 283,
        "score": 199.87
    },
    {
        "id": 90,
        "score": 199.86
    },
    {
        "id": 132,
        "score": 199.45999999999998
    },
    {
        "id": 375,
        "score": 199.32
    },
    {
        "id": 526,
        "score": 199.06
    },
    {
        "id": 120,
        "score": 198.91
    },
    {
        "id": 410,
        "score": 198.76999999999998
    },
    {
        "id": 614,
        "score": 198.24
    },
    {
        "id": 679,
        "score": 198.23000000000002
    },
    {
        "id": 318,
        "score": 197.98000000000002
    },
    {
        "id": 241,
        "score": 197.83000000000004
    },
    {
        "id": 469,
        "score": 197.70999999999998
    },
    {
        "id": 495,
        "score": 197.69
    },
    {
        "id": 539,
        "score": 197.55
    },
    {
        "id": 394,
        "score": 197.29000000000002
    },
    {
        "id": 366,
        "score": 197.28
    },
    {
        "id": 664,
        "score": 197.26999999999998
    },
    {
        "id": 581,
        "score": 197.00999999999996
    },
    {
        "id": 487,
        "score": 196.73999999999998
    },
    {
        "id": 702,
        "score": 196.73000000000002
    },
    {
        "id": 562,
        "score": 196.47
    },
    {
        "id": 163,
        "score": 196.33999999999997
    },
    {
        "id": 185,
        "score": 195.65999999999997
    },
    {
        "id": 201,
        "score": 195.52
    },
    {
        "id": 237,
        "score": 195.4
    },
    {
        "id": 446,
        "score": 195.13
    },
    {
        "id": 192,
        "score": 195.12
    },
    {
        "id": 144,
        "score": 194.57
    },
    {
        "id": 622,
        "score": 194.3
    },
    {
        "id": 46,
        "score": 194.16000000000003
    },
    {
        "id": 51,
        "score": 194.02999999999997
    },
    {
        "id": 47,
        "score": 193.90000000000003
    },
    {
        "id": 580,
        "score": 193.63
    },
    {
        "id": 447,
        "score": 192.82
    },
    {
        "id": 73,
        "score": 192.68
    },
    {
        "id": 528,
        "score": 192.67000000000002
    },
    {
        "id": 102,
        "score": 192.4
    },
    {
        "id": 321,
        "score": 192.39999999999998
    },
    {
        "id": 442,
        "score": 192.27999999999997
    },
    {
        "id": 25,
        "score": 192.13
    },
    {
        "id": 701,
        "score": 192.12
    },
    {
        "id": 296,
        "score": 191.99
    },
    {
        "id": 427,
        "score": 191.99
    },
    {
        "id": 732,
        "score": 191.72000000000003
    },
    {
        "id": 426,
        "score": 191.59
    },
    {
        "id": 471,
        "score": 191.45000000000002
    },
    {
        "id": 704,
        "score": 191.17000000000002
    },
    {
        "id": 422,
        "score": 190.89999999999998
    },
    {
        "id": 604,
        "score": 190.36999999999998
    },
    {
        "id": 206,
        "score": 190.23
    },
    {
        "id": 117,
        "score": 189.13
    },
    {
        "id": 717,
        "score": 189.13
    },
    {
        "id": 345,
        "score": 188.87
    },
    {
        "id": 690,
        "score": 188.61
    },
    {
        "id": 88,
        "score": 188.49
    },
    {
        "id": 159,
        "score": 188.47000000000003
    },
    {
        "id": 468,
        "score": 188.45999999999998
    },
    {
        "id": 436,
        "score": 188.33999999999997
    },
    {
        "id": 79,
        "score": 188.32999999999998
    },
    {
        "id": 497,
        "score": 187.92000000000002
    },
    {
        "id": 473,
        "score": 187.64
    },
    {
        "id": 83,
        "score": 186.85000000000002
    },
    {
        "id": 179,
        "score": 186.70000000000002
    },
    {
        "id": 359,
        "score": 186.3
    },
    {
        "id": 596,
        "score": 186.02999999999997
    },
    {
        "id": 733,
        "score": 185.88
    },
    {
        "id": 405,
        "score": 185.61
    },
    {
        "id": 483,
        "score": 185.60999999999999
    },
    {
        "id": 591,
        "score": 185.48000000000002
    },
    {
        "id": 118,
        "score": 185.22
    },
    {
        "id": 110,
        "score": 185.2
    },
    {
        "id": 547,
        "score": 184.79000000000002
    },
    {
        "id": 202,
        "score": 184.52
    },
    {
        "id": 620,
        "score": 183.86
    },
    {
        "id": 125,
        "score": 182.76999999999998
    },
    {
        "id": 273,
        "score": 182.51
    },
    {
        "id": 180,
        "score": 182.23
    },
    {
        "id": 297,
        "score": 182.1
    },
    {
        "id": 240,
        "score": 181.26999999999998
    },
    {
        "id": 161,
        "score": 181.02
    },
    {
        "id": 383,
        "score": 181.01000000000002
    },
    {
        "id": 216,
        "score": 180.73000000000002
    },
    {
        "id": 638,
        "score": 180.71999999999997
    },
    {
        "id": 335,
        "score": 180.59
    },
    {
        "id": 602,
        "score": 180.45000000000005
    },
    {
        "id": 509,
        "score": 180.32
    },
    {
        "id": 279,
        "score": 180.19
    },
    {
        "id": 629,
        "score": 180.18
    },
    {
        "id": 644,
        "score": 180.17000000000002
    },
    {
        "id": 357,
        "score": 179.5
    },
    {
        "id": 320,
        "score": 179.38
    },
    {
        "id": 55,
        "score": 179.11
    },
    {
        "id": 512,
        "score": 178.95999999999998
    },
    {
        "id": 65,
        "score": 178.7
    },
    {
        "id": 546,
        "score": 178.29000000000002
    },
    {
        "id": 330,
        "score": 177.76
    },
    {
        "id": 141,
        "score": 177.75
    },
    {
        "id": 639,
        "score": 177.07
    },
    {
        "id": 519,
        "score": 176.94
    },
    {
        "id": 245,
        "score": 176.51
    },
    {
        "id": 107,
        "score": 175.71000000000004
    },
    {
        "id": 255,
        "score": 175.57
    },
    {
        "id": 48,
        "score": 175.43
    },
    {
        "id": 17,
        "score": 175.3
    },
    {
        "id": 680,
        "score": 174.90000000000003
    },
    {
        "id": 164,
        "score": 174.9
    },
    {
        "id": 587,
        "score": 174.48000000000002
    },
    {
        "id": 147,
        "score": 174.36
    },
    {
        "id": 78,
        "score": 174.22000000000003
    },
    {
        "id": 198,
        "score": 173.81
    },
    {
        "id": 548,
        "score": 173.54000000000002
    },
    {
        "id": 266,
        "score": 173.41
    },
    {
        "id": 155,
        "score": 173.4
    },
    {
        "id": 352,
        "score": 173.26999999999998
    },
    {
        "id": 458,
        "score": 173.13
    },
    {
        "id": 94,
        "score": 172.86
    },
    {
        "id": 554,
        "score": 172.45
    },
    {
        "id": 338,
        "score": 170.54000000000002
    },
    {
        "id": 108,
        "score": 170.43
    },
    {
        "id": 409,
        "score": 170.43
    },
    {
        "id": 712,
        "score": 170.28999999999996
    },
    {
        "id": 464,
        "score": 170.16
    },
    {
        "id": 71,
        "score": 170
    },
    {
        "id": 131,
        "score": 169.74
    },
    {
        "id": 260,
        "score": 169.32999999999998
    },
    {
        "id": 626,
        "score": 169.07
    },
    {
        "id": 535,
        "score": 168.94
    },
    {
        "id": 708,
        "score": 168.26
    },
    {
        "id": 425,
        "score": 166.06
    },
    {
        "id": 170,
        "score": 165.28
    },
    {
        "id": 10,
        "score": 164.97
    },
    {
        "id": 621,
        "score": 164.57999999999998
    },
    {
        "id": 143,
        "score": 164.47
    },
    {
        "id": 324,
        "score": 164.04000000000002
    },
    {
        "id": 28,
        "score": 163.49
    },
    {
        "id": 721,
        "score": 162.54999999999998
    },
    {
        "id": 119,
        "score": 162.14999999999998
    },
    {
        "id": 257,
        "score": 162
    },
    {
        "id": 525,
        "score": 161.33
    },
    {
        "id": 542,
        "score": 158.88
    },
    {
        "id": 451,
        "score": 158.63
    },
    {
        "id": 682,
        "score": 157.53
    },
    {
        "id": 342,
        "score": 156.44
    },
    {
        "id": 505,
        "score": 156.18
    },
    {
        "id": 510,
        "score": 154.41
    },
    {
        "id": 472,
        "score": 154.28
    },
    {
        "id": 243,
        "score": 154.14000000000001
    },
    {
        "id": 61,
        "score": 154.01
    },
    {
        "id": 308,
        "score": 154
    },
    {
        "id": 493,
        "score": 153.60000000000002
    },
    {
        "id": 323,
        "score": 153.04000000000002
    },
    {
        "id": 530,
        "score": 153.04
    },
    {
        "id": 564,
        "score": 152.79
    },
    {
        "id": 537,
        "score": 151.67999999999998
    },
    {
        "id": 229,
        "score": 151.14000000000001
    },
    {
        "id": 543,
        "score": 149.51999999999998
    },
    {
        "id": 668,
        "score": 148.98
    },
    {
        "id": 689,
        "score": 148.85999999999999
    },
    {
        "id": 371,
        "score": 148.57
    },
    {
        "id": 305,
        "score": 147.89000000000001
    },
    {
        "id": 478,
        "score": 146.13
    },
    {
        "id": 691,
        "score": 144.78
    },
    {
        "id": 438,
        "score": 144.24
    },
    {
        "id": 412,
        "score": 143.56
    },
    {
        "id": 213,
        "score": 142.06
    },
    {
        "id": 319,
        "score": 140.84
    },
    {
        "id": 5,
        "score": 140.3
    },
    {
        "id": 53,
        "score": 139.2
    },
    {
        "id": 91,
        "score": 139.07
    },
    {
        "id": 715,
        "score": 138.66000000000003
    },
    {
        "id": 646,
        "score": 136.65
    },
    {
        "id": 270,
        "score": 135
    },
    {
        "id": 713,
        "score": 134.46
    },
    {
        "id": 651,
        "score": 132.55
    },
    {
        "id": 676,
        "score": 131.89000000000001
    },
    {
        "id": 592,
        "score": 131.34
    },
    {
        "id": 134,
        "score": 131.06
    },
    {
        "id": 114,
        "score": 129.45
    },
    {
        "id": 312,
        "score": 129.44
    },
    {
        "id": 367,
        "score": 120.36
    },
    {
        "id": 188,
        "score": 120.35
    },
    {
        "id": 268,
        "score": 117.78999999999999
    },
    {
        "id": 408,
        "score": 116.14999999999999
    },
    {
        "id": 492,
        "score": 113.69
    },
    {
        "id": 619,
        "score": 108.14999999999999
    },
    {
        "id": 222,
        "score": 105.17
    }
]

const mint_count = GNOMEY_RANK.length;

export const GetMintRarityForAttribute = (attr, val) =>{
    // console.log(attr,val);
    const trait = GNOMEY_STATS.filter(f => f.trait_type === attr);
    // console.log(trait[0].values[val]);
    return parseFloat((trait[0].values[val] / mint_count * 100).toFixed(4));
}

export const GetGnomeyRankLevel = (tokenId) => {
    const index = GNOMEY_RANK.findIndex(ix => +ix.id === +tokenId)
    return (GNOMEY_RANK.length - index).toString() + "/" + GNOMEY_RANK.length.toString();
}
export const GetGnomeyRankType = (tokenId) => {
    console.log("Tokenid", tokenId);
    const index = GNOMEY_RANK.findIndex(ix => +ix.id === +tokenId)
    const tierBreak = Math.floor(mint_count / 5);
    const deltaBreak = mint_count % 5;
    let tier1Break = tierBreak + deltaBreak;
    
    console.log("tier1Break", tier1Break);
    console.log("tierBreak", tierBreak);
    console.log("deltaCount", deltaBreak);
    console.log("IX", index);

    if(index === -1){
        return "Pending...";
    }
    if(index <= tier1Break){
        return "Common";
    }
    const tier2Break = tier1Break + tierBreak;
    if( index > tier1Break && index <= tier2Break){
        return "Uncommon";
    }

    const tier3Break = tier2Break + tierBreak;
    if( index > tier2Break && index <= tier3Break){
        return "Rare";
    }
    
    const tier4Break = tier3Break + tierBreak;
    if( index > tier3Break && index <= tier4Break){
        return "Epic";
    }

    if( index > tier4Break){
        return "Legendary";
    }
}