import styled from "styled-components";

export const PageWrapperDiv = styled.div` 
    display: flex;    
    justify-content: center;
    align-content: center;    
    width: 100%;    
    
    @media screen and (max-width: 768px) {      
        margin: 0px;
        width: 100%;        
        // margin-top: 85px;        
    }
`;


export const MenuButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  height: 25px;
  width: 75px;
  background-color: #C0C0C0;
  color: #000000;
  border: 2px solid #666666;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-weight: bolder;
  font-size: 1.2em;
  transition: all 0.3s ease; 

  &:hover{
    background-color: #808080; 
    color: #FFFFFF;
    cursor: pointer; 
  }

   @media screen and (max-width: 768px) {
    margin: 15px;
   }
`;
export const BodyTextDiv = styled.div`  
    align-self: center;  
    text-align: center;    
    font-size: 28px;         
    font-weight: bold;
    // color: black;
    font-family: Laila Bold;
    color: #060fce;
    position: relative;    
    fontSize: 2em;
    width: 75%;   
    position: relative;
    top: 35px;
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 1.3em;
      // margin-bottom: 10px;      
      top: 125px;
    }           
`;

export const TextDiv = styled.div`    
    text-align: center;    
    font-size: 20px;  
    font-weight: bold;
    color: transparent;
    background-image: linear-gradient(to bottom, #ffffff, #c0c0c0); /* White to light grey gradient */  
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-background-clip: text; /* Apply background to text */
    background-clip: text;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);        
     @media screen and (max-width: 768px) {
      margin: 8px;
     }
`;

export const ClickableImage = styled.img`
&:hover{
    cursor: pointer;
}
`;

export const  StandardButton = styled.button`
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
margin-right: 10px;
margin-left: 10px;
height: 50px;
width: 150px;
background-color: #C0C0C0;
color: #000000;
border: 2px solid #666666;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
border-radius: 5px;
font-weight: bolder;
font-size: 1.2em;
transition: all 0.3s ease; 

&:hover{
  background-color: #808080; 
  color: #FFFFFF;
  cursor: pointer; 
}
&:hover:disabled{
  cursor: not-allowed;
}

 @media screen and (max-width: 768px) {
  margin: 15px;
 }
`;
export const TokenMenuImg = styled.img`
    width: 75px;

    &:hover{
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      width: 50px;
    }
`;


export const LabelValuePairDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 10px;
padding: 10px;
border: 2px solid #666666;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
 font-weight: bold;
   width: 80%;
`;
export const TextLabelDiv = styled.div`
  margin-right: 10px;
  font-weight: bold;   
  font-weight: bold;
    color: transparent;
    background-image: linear-gradient(to bottom, #ffffff, #c0c0c0); /* White to light grey gradient */  
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-background-clip: text; /* Apply background to text */
    background-clip: text;
    

     @media screen and (max-width: 768px) {
      margin: 0px;
      justify-content: center;
     }
`;

export const TextValueDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const InputTextBox = styled.input`
  color: #060fce;
  height: 35px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, #ffffff, #c0c0c0);
  text-align: center;
  font-size: .8em;
  width: 200px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  &[type=number] {
    -moz-appearance: textfield;
  }
`


export const StyledButton = styled.button`
    position: relative;
    font-family: Laila Bold;
    color: white;
    border: 1px solid #060fce;
    border-radius: 90px;
    background-color: #060fce;    
    width: auto;
    padding: 20px;
    font-size: 1em;
    min-width: 100px;
    max-width: 300px;
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      font-size: .7em;
      min-width: 0px;
      width: 50px;
      padding: 5px;     
    }
`;

