import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import styled from "styled-components";
import DisclaimerText from "../components/disclaimer";
import { UserMessageStateActions } from "../store/state/usermessagestate";
import { AddUserGnomeysToMetaMask, GetEtherBalanceForUser, GetSaleStatus, GetTotalSupply, GetUsersGnomeys, GetWhiteListStatusForUser, MintGnomey, sleep } from "../code/gnomeynft_code";
import netConfig from "../config"
import { BigNumber} from 'ethers';
import MintConditionsModal from "../modals/mintconditions";
import bgImg from '../assets/app_bg.png';
import { StyledButton } from "../components";
import AppMenu from "../components/menu";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import mm from '../assets/metamask.svg';
import GnomeyStatsModal from "../modals/gnmoeystats";
import { LinearProgress } from "@mui/material";

const InsideWrapperDiv = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    
    @media screen and (max-width: 768px) {             
     
    }
`;

const WhiteListedDiv = styled.div`
   
   
`;



const PageWrapperDiv = styled.div` 
    display: flex;    
    justify-content: center;
    align-content: center;    
    width: 100%;    
    background-image:  url(${bgImg});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) {      
        margin: 0px;
        width: 100%;        
        
        // margin-top: 85px;        
    }
`;
const BodyDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    align-items: center;
    
    column-gap: 2rem;
    width: 55%;
    height: 70%;
    overflow-y: auto;
    scrollbar-color: #787EF9 #151ED9;
    scrollbar-width: thin;
    background-color: rgba(229,242,85,0.9);
    border-radius: 30px;
    @media screen and (max-width: 768px) {   
        width: 88%;
        height: 90%;
        flex-direction: column;  
        position: relative;
        border-radius: 30px 30px 0px 0px;   
    }
     @media screen and (max-width: 568px) {  
        
     }
`;

const SectionDiv = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center; 
    row-gap: 10px;   
    align-items: center;
    color:  #060fce;    
    width: 90%;
    font-family: Laila Bold;
    font-size: 1.5em;    
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);     
    @media screen and (max-width: 768px) {
        width: 99%;
        font-size: 1.0em;
        
                           
     }
`;

const ButtonSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aling-content: center;    
    width: 30%;
    color:  #060fce;
    height: 300px;
    padding: 15px;
    font-family: Laila Bold;
    font-size: 1.5em;

     @media screen and (max-width: 768px) {
        font-size: 1em;
        width: 99%;  
        height: auto;                              
     }
`;
const TextDiv = styled.div`
font-family: Laila Bold;
 @media screen and (max-width: 768px) {
    font-size: 0.7em;
    display: flex;
    justify-content: center;
    text-align: center;
 }
`;


const StyledMintButton = styled.button`
    position: relative;
    font-family: Laila Bold;
    color: white;
    border: 1px solid #060fce;
    border-radius: 90px;
    background-color: #060fce;    
    width: auto;
    padding: 20px;
    font-size: 1em;
    min-width: 100px;
    max-width: 300px;
    &:hover{
        cursor: pointer;
    }
    &:disabled{
        cursor: not-allowed;
        color: grey;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.5em;
      min-width: 0px;
      width: 75px;
      padding: 5px;     
    }
`;

const MMImage = styled.img`
@media screen and (max-width: 768px) {
    display: none;
}
`;

const NftListDiv = styled.div`
display: flex;
justify-content: center;
flex-wrap: wrap;
column-gap: 10px;
row-gap: 5px;
`;

const NFTPage = () => {
    const authStatus= useSelector(state => state.authReduxState.isAuthenticated)
    const walletAddress = useSelector(state => state.authReduxState.walletAddress)  
    const [saleStatus, SetSaleStatus] = useState({isPublicSale: false, isWhiteListSale: false})
    const [isWhiteListed, SetIsWhiteListed] = useState(false);
    const [supplyCount, SetSupplyCount] = useState(0);
    const [showConditions, SetShowConditions] = useState(false);
    const [acceptedTermsAlready, SetAcceptedTermsAlready] = useState(false);
    const [userTokens, SetUserTokens] = useState([]);
    const [userBalance, SetUserBalance] = useState(0.0);
    const [showGnomeyStats, SetShowGnomeyStats]  = useState({show: false, token: 0});
    const [isWorking, SetIsWorking] = useState(false);
    const dispatch = useDispatch();

   

    useEffect(() => {

        const getSupplyCount = async () => {
            const totalSupply = await GetTotalSupply(netConfig);            
            SetSupplyCount(BigNumber.from(totalSupply.toString()).toNumber());
        }

        getSupplyCount();
        const interval = setInterval(getSupplyCount, 4500);

        return(() => {
            clearInterval(interval);
        })
    })
    useEffect(() => {
        

        const Init = async () => {
             const {isPublicSale, isWhiteListSale}  = await GetSaleStatus(netConfig);
             SetSaleStatus({isPublicSale, isWhiteListSale});

            //  console.log({isPublicSale, isWhiteListSale});

             if(authStatus){
                const isWhiteListed = await GetWhiteListStatusForUser(netConfig,walletAddress);
                // console.log("iswhitelisted", isWhiteListed);
                SetIsWhiteListed(isWhiteListed);
                await LoadUsersGnomeyNFT();
                await GetUsersBalance();
             }
        }

        Init();
    }, [authStatus, walletAddress])

    const GetUsersBalance = async () => {
        const ethBalance  = await GetEtherBalanceForUser(walletAddress);
        SetUserBalance(ethBalance.div(BigNumber.from(10).pow(18)).toString());
        console.log("balance:",ethBalance.div(BigNumber.from(10).pow(18)).toString());
    }
    const onMintClick = async () => {       
        if(!authStatus){
            dispatch(UserMessageStateActions.setUserMessage({show: true, message:`Connect Wallet`}));
            return;
        }
        const {isPublicSale, isWhiteListSale} = {...saleStatus}
        if(!isPublicSale && !isWhiteListSale){
            dispatch(UserMessageStateActions.setUserMessage({show: true, message:`Mint not live yet. Join socials for updates.`}));
        }else if(isWhiteListSale){
            const isWhiteListed = await GetWhiteListStatusForUser(netConfig,walletAddress);
            SetIsWhiteListed(isWhiteListed);
            // console.log(isWhiteListed);
            if(isWhiteListed === false){
                dispatch(UserMessageStateActions.setUserMessage({show: true, message:`Your address is not whitelisted. Please wait for the public sale.`}));
            }else{
                //TODO: Show terms/conditions popup to allow mint.
                //just have a callback function from the accept dialog and go straight to minting.
                if(!acceptedTermsAlready){
                    SetShowConditions(true);                  
                }else{
                    await DoMintGnomey();
                }
            }           
            
        }else if(isPublicSale){
            if(!acceptedTermsAlready){
                SetShowConditions(true);                  
            }else{
                await DoMintGnomey();
            }
        }
    }

    const DoMintGnomey = async () => {
        SetIsWorking(true);
        await MintGnomey(netConfig, walletAddress).then( async tx => {
            await LoadUsersGnomeyNFT();
            await GetUsersBalance();
            dispatch(UserMessageStateActions.setUserMessage({show: true, message: "Successful mint!."}));
        }).catch(c => {            
            dispatch(UserMessageStateActions.setUserMessage({show: true, message: c.message}));
        }).finally( f => {
            SetIsWorking(false)
        });
    }
    const onCloseConditionsModal = async (answer) => {
        SetShowConditions(false);
        if(answer === "NO"){            
            // dispatch(UserMessageStateActions.setUserMessage({show: true, message:`Terms & Conditions not accepted`}));
            SetAcceptedTermsAlready(false);
        }else if(answer === "YES"){  
            SetAcceptedTermsAlready(true);
            //TODO: validate user has enough ROSE
            //TODO: Validation if exceeded allowance during whitelist phase.
           await DoMintGnomey();
         
        }
    }

    
    const LoadUsersGnomeyNFT = async () => {
        SetIsWorking(true);
       const userTokens = await GetUsersGnomeys(netConfig,walletAddress);       
       //TODO: Get JSON Metadata based on TokeURI. Set to State the JSON objects
    //    console.log("user Tokens", userTokens);
       for(let i = 0; i < userTokens.length; i++){
        // console.log("URI", userTokens[i].metaDataURI);
        sleep(1200);
        const response = await fetch(userTokens[i].metaDataURI, {           
           mode: 'cors', // Ensure that CORS is enabled
            credentials: 'same-origin', // Use 'same-origin' if the request is same-origin
            cache: 'default', // Use default caching behavior
        })
        .catch( c => {
                dispatch(UserMessageStateActions.setUserMessage({show: true, message: "Could not fetch your Gnomey's. Brave browser and ad blockers may block IPFS."}));                
        });
        if (!response.ok) {
            dispatch(UserMessageStateActions.setUserMessage({show: true, message: "Could not fetch your Gnomey's. Brave browser and ad blockers may block IPFS."})); 
            break;                           
        }
        const md = await response.json();
        // console.log("metadata json:", md);
        userTokens[i] = {...userTokens[i], imageURI: md.image, data: md};
       }       
       //console.log(userTokens);       
       SetUserTokens(userTokens);
       SetIsWorking(false);
    }

    const AddToMM =  () => {
        // console.log(userTokens.map((v, ix) => {return v.tokenId}))
        // console.log(netConfig);
         AddUserGnomeysToMetaMask(netConfig.addresses.gnomeyNFT, userTokens.map((v, ix) => {return v.tokenId}) );
    }

    const onClickGnomeyNFT = (gnomeyId) => {
        SetShowGnomeyStats({...showGnomeyStats,show: true, tokenId: gnomeyId});
    }
    const onCancelShowGnomeyStats = () =>{
        SetShowGnomeyStats({...showGnomeyStats,show: false, token: null})
    }
    return(
        <PageWrapperDiv>
            {
                showConditions && <MintConditionsModal onClose={onCloseConditionsModal} />
            }
            {
            showGnomeyStats.show === true && <GnomeyStatsModal gnomey={showGnomeyStats.tokenId} clickHandler={onCancelShowGnomeyStats} />
            }
            <InsideWrapperDiv >  
           <AppMenu />
           
            <BodyDiv>
                <SectionDiv style={{display: "flex",justifyContent: "center", alignItems: "center", alignContent:"center", fontSize:"1.8em", margin: "50px" }}>                    
                    <TextDiv>The first step on your botanical journey is to cultivate your very first creation.</TextDiv>
                    <TextDiv>
                        Garden Gnomeys are 1-of-1 generative NFT with over 60 traits of varying rarities.
                    </TextDiv>
                    <TextDiv>
                            Max Supply: 2000
                    </TextDiv>
                    <TextDiv>
                        Gnomeys catch airdrops of seeds when new seeds are discovered.
                    </TextDiv>
                    </SectionDiv>
                
                {/* <SectionDiv style={{display: "flex",justifyContent: "center", alignItems: "center", alignContent:"center", fontSize:"1.8em", margin: "50px" }}>
                    <TextDiv style={{width: "auto"}}>{saleStatus.isPublicSale && "Public Sale Started"}</TextDiv>
                    <TextDiv style={{width: "auto"}}>{saleStatus.isWhiteListSale && "Whitelist Sale Started"}</TextDiv>
                    <TextDiv style={{width: "auto"}}>{!saleStatus.isWhiteListSale && !saleStatus.isPublicSale && "Sale Not Started"}</TextDiv>
                </SectionDiv> */}

                <div style={{display: "flex",justifyContent: "center",alignItems: "center",width: "100%",}}>                
                    <SectionDiv>
                        <div style={{display: "flex", flexDirection: "column",rowGap: "0.5rem",alignItems: "center",width: "100%"}}>                
                            <TextDiv >Mint: 175 $ROSE</TextDiv>                                                
                            { authStatus && (saleStatus.isWhiteListSale || (!saleStatus.isPublicSale && !saleStatus.isWhiteListSale)) && <TextDiv>Whitelist max 6 mints/wallet</TextDiv> }                       
                            { authStatus && (saleStatus.isWhiteListSale || (!saleStatus.isPublicSale && !saleStatus.isWhiteListSale)) && <TextDiv style={{display: "flex", alignItems: "center", columnGap: ".25rem"}}>
                                Status: {walletAddress.slice(0,5) + "..." + walletAddress.slice(-5)} {!isWhiteListed ? <ClearIcon style={{color: "red", border:"2px solid red", borderRadius: "90px"}} /> : <CheckIcon style={{color: "green", border: "2px solid green", borderRadius: "90px"}} />} 
                            </TextDiv>                        
                            }
                            {isWhiteListed === false && (saleStatus.isWhiteListSale === true || (saleStatus.isPublicSale && saleStatus.isWhiteListSale) )  && <WhiteListedDiv iswhitelisted={isWhiteListed.toString()}>{ authStatus ? "Your address is not White Listed" : "Login to check Whitelist status"}</WhiteListedDiv> }
                            {isWhiteListed === true && (saleStatus.isWhiteListSale === true || (saleStatus.isPublicSale && saleStatus.isWhiteListSale) )  && <WhiteListedDiv iswhitelisted={isWhiteListed.toString()}>Congratulations! Your address whitelisted</WhiteListedDiv> }                        
                                                    
                        </div>                                              
                    </SectionDiv> 
                   
                
                </div>
                <ButtonSection>
                    <StyledMintButton disabled={isWorking} style={{width: "300px"}} onClick={onMintClick}>Mint Gnomey</StyledMintButton>
                    {authStatus !== true && <TextDiv style={{color: "red",marginTop: "3px"}}>Connect Wallet</TextDiv>}  
                    <TextDiv style={{marginTop: "20px"}}>Remaining Supply: {2000-supplyCount > 0 ? 2000 -supplyCount : "Sold Out"}</TextDiv> 
                </ButtonSection>  
                {
                    isWorking && <div style={{width: "200px", alignSelf: "center", marginTop: "10px", padding: "20px"}}>
                            <LinearProgress />
                        </div>
                }
                <SectionDiv style={{borderTop: "1px solid", justifyContent: "center"}}>
                    <TextDiv style={{width: "auto", fontSize: "1.2em"}}>Your Gnomey Collection ({userTokens.length})</TextDiv>
                    {isWorking === true &&  <div>Loading your Gnomeys...</div>}
                    <NftListDiv>
                        {
                            userTokens.map( (v,ix) => {
                                sleep(200);
                                return <div key={ix} style={{display: "flex", flexDirection: "column", rowGap: "1rem",alignItems: "center", alignContent: "center"}}>
                                    <img crossOrigin="anonymous" style={{height: "100px", cursor: "pointer"}} alt={(ix + 1).toString()}  src={ v.imageURI} onClick={() => onClickGnomeyNFT(v)} />                            
                                    {/* <img style={{height: "100px", cursor: "pointer"}} alt={(ix + 1).toString()}  src="ipfs://QmRMcTXXywof2a3fJsTm2FyHmNEiXYTChNC9xNimLbY4Az/112.png" onClick={() => onClickGnomeyNFT(v)} />                             */}
                                    
                                </div>
                            })
                        }
                        {userTokens.length === 0 && isWorking === false && <div>Mint yourself a Gnomey!!</div>}
                       
                    </NftListDiv>
                    <TextDiv onClick={AddToMM} style={{display: "flex",justifyContent: "center",paddingTop: "20px", flexWrap: "nowrap",width: "100%", alignItems: "center",fontSize: ".75em"}}>CA: {netConfig.addresses.gnomeyNFT}<MMImage style={{height: "20px" , cursor: "pointer"}} src={mm} alt="" /></TextDiv>
                </SectionDiv>
            </BodyDiv>
            </InsideWrapperDiv>  
                   
        </PageWrapperDiv>
    )
}

export default  NFTPage;