import styled from "styled-components";

const DisclaimerTextDiv = styled.div`    
    margin-top: auto;
    font-family: Laila Bold;
    font-weight: bolder;
    color: white;
    width: 85%;
    align-self: center;
    z-index: 3;
    @media screen and (max-width: 768px) {
        font-size: 0.5em;
        width: 97%;
    }
`;
const DisclaimerText = () => {


    return(
        <DisclaimerTextDiv>
           DISCLAIMER: Cryptocurrencies and NFTs are highly volatile and speculative assets with no guaranteed financial returns. Before purchasing, you should conduct thorough research and exercise caution. All contracts are verified, and ownership is renounced. Gnomey NFTs are for entertainment purposes only and should not be purchased with expectations of financial gains. The Sapphire Garden is solely for entertainment purposes.
        </DisclaimerTextDiv> 
    )
}

export default DisclaimerText;