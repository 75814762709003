import { configureStore } from '@reduxjs/toolkit';
import authStateReducer from './state/authstate';
import sideDrawerReducer from './state/sidedrawerstate'
import userMessageStateReducer from './state/usermessagestate';

const store = configureStore({
    reducer: {  
        authReduxState: authStateReducer,   
        sideDrawerState: sideDrawerReducer,   
        userMessageReduxState: userMessageStateReducer,         
   } 
});

export default store;