import {useNavigate, useParams } from "react-router";
import {  TokenMenuImg } from "../components"
import SeedsOrchids from "../seeds/seedsOrchids";
import SeedsLillies from "../seeds/seedsLillies";
import SeedsTulips from "../seeds/seedsTulips";
import {TokenLogos} from "../assets";
import styled from "styled-components";
import DisclaimerText from "../components/disclaimer";
import AppMenu from "../components/menu";
import bgImg from '../assets/app_bg.png';
import YourProjectHereSeeds from "../seeds/yourprojecthere";


const PageWrapperDiv = styled.div` 
    display: flex;    
    justify-content: center;
    align-content: center;    
    width: 100%;    
    background-image:  url(${bgImg});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) {      
        margin: 0px;
        width: 100%;  
    }
`;

const SeedsNavigationDiv = styled.div`
    display: flex;
    width: 30%;
    justify-content: space-between;   
    padding: 20px;
    position: relative;
    top: 25px;
    @media screen and (max-width: 768px) {             
      width: 80%;  
      top: 0px;
      padding: 0px;
    }
`;

const FlowDiv = styled.div`
 display: flex;
 width: 100%;
 align-items: center;
 flex-direction: column;
 @media screen and (max-width: 768px) {             
    width: 100%;   
     
 }
`;
const SeedsContainer = styled.div`
 display: flex;
 justify-content: center;
@media screen and (max-width: 768px) {
    position: relative;
    top: 0px;
    overflow-y: auto;    
    
}
`;

const SeedsPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    
    
    const onChangeSeedsPage = (pageName) =>{
        if(pageName === "orchids"){
            navigate("/seeds/orchids");
        }else if (pageName === "lillies"){
            navigate("/seeds/lillies");
        }else if(pageName === "tulips"){
            navigate("/seeds/tulips");
        }else if(pageName === "YOURPROJECT"){
            navigate("/seeds/yourproject");
        }
    }
    return(
        <PageWrapperDiv>              
            <FlowDiv>
            <AppMenu />
            <SeedsNavigationDiv >
                <div onClick={() => onChangeSeedsPage("orchids")}><TokenMenuImg  src={TokenLogos.OrchidLogo} alt="Orchids" /></div>
                <div onClick={() => onChangeSeedsPage("lillies")}><TokenMenuImg  src={TokenLogos.LillyLogo} alt="Lillies" /></div> 
                <div onClick={() => onChangeSeedsPage("YOURPROJECT")}><TokenMenuImg  src={TokenLogos.YourProjectHere} alt="yourproject" /></div> 
            </SeedsNavigationDiv> 
            <SeedsContainer>
                {params.seedName === "orchids" && <SeedsOrchids />}
                {params.seedName === "lillies" && <SeedsLillies />}
                {params.seedName === "tulips" && <SeedsTulips />}
                {params.seedName === "yourproject" && <YourProjectHereSeeds />}
            </SeedsContainer>
         
           </FlowDiv>   
           
        </PageWrapperDiv>
    )
}

export default  SeedsPage;