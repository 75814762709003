import gnomeyNFTABI from './abi/gnomeynft.json';

const ABIs = {
    gnomeyNFT: gnomeyNFTABI
}


const NetworkConfig = {
    LocalHardHat: {
        name:"Local HardHat",
        chainID: "0x7A69",
        rpc: "http://127.0.0.1:8545/",
        addresses: {
            gnomeyNFT: "0xcf7ed3acca5a467e9e704c703e8d87f634fb0fc9"
        },
        ABI: ABIs
    },
     OasisTestNet: {
        name:"Oasis Sapphire Testnet",
        chainID:  "0x5aff",
        rpc: "https://testnet.sapphire.oasis.dev",
        addresses: {
            gnomeyNFT: "0xd9FBE086D149a83039bc291b7101e892F53BD431"
        },
        ABI: ABIs
    },
    OasisMainNet: {
        name:"Oasis Sapphire Mainnet",
        chainID: "0x5afe",
        rpc: "https://sapphire.oasis.io",
        addresses: {
            gnomeyNFT: "0xd9FBE086D149a83039bc291b7101e892F53BD431"
        },
        ABI: ABIs
    }
}

export default NetworkConfig.OasisMainNet;

// export default process.env.REACT_APP_TESTNET === "true" ? 
//             process.env.REACT_APP_LOCAL === "true" ? NetworkConfig.LocalHardHat : NetworkConfig.OasisTestNet 
//              : NetworkConfig.OasisMainNet;