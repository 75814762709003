import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { GetGnomeyRankLevel, GetGnomeyRankType, GetMintRarityForAttribute } from '../code/mintstats';

 
const GnomeyStatsDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    position: fixed;
    top: 50%; /* Position at 50% from the top */
    left: 50%; /* Position at 50% from the left */
    transform: translate(-50%, -50%); /* Center the element */
    background-color: #060fce;
    border: 2px solid black;
    border-radius: 30px;    
    z-index: 100;
    width: 400px;
    color:  white;  
    font-family: Laila Bold;
    font-size: 1.5em;    
    // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); 
    &:hover{
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {             
     width: 85%;
     font-size: 1.1em;
    }
`;

const AttributesGridDiv = styled.div`
    display: flex;    
    flex-direction: column;
    font-size:0.7em;
    width: 100%;
    row-gap: .5rem;
`;

const AttributeGridRowDiv = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    width: 100%;
`;
const AttributeGridCellDev = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 30%;
    
`;

const GnomeyRankDiv = styled.div`
font-size: 1em;
font-weight: bolder;
text-transform: uppercase;
color: ${(props) => props.rank === "Legendary" ? "yellow" : props.rank === "Epic" ? "#C767CA" : props.rank === "Rare" ? "#57BBF4" : props.rank === "Uncommon" ? "Orange" : props.rank === "Common" ? "Gray" : "White"}
`;
const GnomeyStatsModalImplementation = (props) => {

console.log(props);
    return (        
        <GnomeyStatsDiv onClick={props.clickHandler}>
            <img  style={{height: "100px"}} src={props.gnomey.data.image} alt=""/>
            <div style={{padding: "10px"}}>#{props.gnomey.data.edition}</div>
            <AttributesGridDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>Background:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'Background')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("Background",props.gnomey.data.attributes.filter( f => f.trait_type === 'Background')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>Base:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'Base')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("Base",props.gnomey.data.attributes.filter( f => f.trait_type === 'Base')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>Hat:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'Hats')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("Hats",props.gnomey.data.attributes.filter( f => f.trait_type === 'Hats')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>Eyes:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'Eyes')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("Eyes",props.gnomey.data.attributes.filter( f => f.trait_type === 'Eyes')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>Mouth:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'Mouth')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("Mouth",props.gnomey.data.attributes.filter( f => f.trait_type === 'Mouth')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>Beard:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'beard')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("beard",props.gnomey.data.attributes.filter( f => f.trait_type === 'beard')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>Clothing:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'Clothing')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("Clothing",props.gnomey.data.attributes.filter( f => f.trait_type === 'Clothing')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>In Hand:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'In hand')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("In hand",props.gnomey.data.attributes.filter( f => f.trait_type === 'In hand')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev>Boots:</AttributeGridCellDev>
                    <AttributeGridCellDev>{props.gnomey.data.attributes.filter( f => f.trait_type === 'Boots')[0].value}</AttributeGridCellDev>
                    <AttributeGridCellDev>{GetMintRarityForAttribute("Boots",props.gnomey.data.attributes.filter( f => f.trait_type === 'Boots')[0].value)}%</AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv style={{borderTop: "1px solid white"}}>
                    <AttributeGridCellDev>Rarity Score:</AttributeGridCellDev>
                    <AttributeGridCellDev><GnomeyRankDiv rank={GetGnomeyRankType(props.gnomey.data.edition)}>{GetGnomeyRankType(props.gnomey.data.edition)}</GnomeyRankDiv></AttributeGridCellDev>
                    <AttributeGridCellDev>
                    {(parseFloat(GetMintRarityForAttribute("Background",props.gnomey.data.attributes.filter( f => f.trait_type === 'Background')[0].value)) +
                    parseFloat(GetMintRarityForAttribute("Base",props.gnomey.data.attributes.filter( f => f.trait_type === 'Base')[0].value)) +
                    parseFloat(GetMintRarityForAttribute("Hats",props.gnomey.data.attributes.filter( f => f.trait_type === 'Hats')[0].value)) +
                    parseFloat(GetMintRarityForAttribute("Eyes",props.gnomey.data.attributes.filter( f => f.trait_type === 'Eyes')[0].value)) +
                    parseFloat(GetMintRarityForAttribute("Mouth",props.gnomey.data.attributes.filter( f => f.trait_type === 'Mouth')[0].value)) +
                    parseFloat(GetMintRarityForAttribute("beard",props.gnomey.data.attributes.filter( f => f.trait_type === 'beard')[0].value)) +
                    parseFloat(GetMintRarityForAttribute("Clothing",props.gnomey.data.attributes.filter( f => f.trait_type === 'Clothing')[0].value)) +
                    parseFloat(GetMintRarityForAttribute("In hand",props.gnomey.data.attributes.filter( f => f.trait_type === 'In hand')[0].value)) +
                    parseFloat(GetMintRarityForAttribute("Boots",props.gnomey.data.attributes.filter( f => f.trait_type === 'Boots')[0].value))).toFixed(4)
                    }
                    </AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev style={{color: "white", justifyContent: "center", width: "100%"}}>
                        {GetGnomeyRankLevel(props.gnomey.data.edition)}
                    </AttributeGridCellDev>
                </AttributeGridRowDiv>
                <AttributeGridRowDiv>
                    <AttributeGridCellDev style={{width: "100%", fontSize: ".8em"}}>
                        Informational Only. Score will change as more Gnomey get minted.
                    </AttributeGridCellDev>
                </AttributeGridRowDiv>
            </AttributesGridDiv>
        </GnomeyStatsDiv>
    )
}


const GnomeyStatsModal = (props) => {
    return (
        <React.Fragment>
            {
                ReactDOM.createPortal(<GnomeyStatsModalImplementation gnomey={props.gnomey} clickHandler={props.clickHandler} />, 
                document.getElementById('modal-gnomeystats-root')
                )
            }    
        </React.Fragment>
    );
}

export default GnomeyStatsModal;