import React, { useState } from "react";
import { useEffect } from "react";
import ReactDOM from 'react-dom';
import styled from  'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { UserMessageStateActions } from "../store/state/usermessagestate";
import { StandardButton } from "../components";


const ModalDiv = styled.div`
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    top: 100px;
    left: 40%;  
    width: 400px;  
    min-height: 75px;
    z-index: 100;
    overflow: hidden;
    border: 3px outset #C4C5C6;
    border-radius: 8px;
    padding-bottom: 10px;
    padding: 10px;
   
    @media screen and (max-width: 768px) {
        justify-self: center;   
        left: 10px;     
        width: 90%;
    }
`;

const ContentWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    max-width: 100%;
    row-gap: 1rem;
`;


const ButtonsDiv = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const MintConditionsImplementation = (props) => {
    const userMessage = useSelector(state => state.userMessageReduxState);
    const [acceptedTerms, SetAcceptedTerms] = useState(false);


    const dispatch = useDispatch();

    const onCancelInfoMsgModal = () => {
        dispatch(UserMessageStateActions.setUserMessage({show: false, message:``}));  
    }

    useEffect(() => {
        if(userMessage.show){            
            setTimeout(onCancelInfoMsgModal, 3000);
        }
       
    },[props.oncancel, userMessage.show])
  
    const onClickTermsLink = ()=> {
        SetAcceptedTerms(true);
    }
    return (
        <ModalDiv>            
                <ContentWrapperDiv >
                        <div>
                            You must accept the full terms, conditions and disclaimers in order to mint a Gnomey NFT
                        </div>

                        <div>
                           <a style={{color: "white"}}  target="_blank" href="https://sapphiregarden.io/#/docs" onClick={onClickTermsLink}>  Click to view terms, conditions and disclaimers.</a>
                        </div>
                   
                   <ButtonsDiv>
                        <StandardButton style={{width: "90px", height: "40px"}} onClick={() => props.onClose("NO")}>Cancel</StandardButton>
                        <StandardButton disabled={!acceptedTerms} style={{width: "90px", height: "40px"}}  onClick={() => props.onClose("YES")}>Accept</StandardButton>
                   </ButtonsDiv>
                   <div>Must click terms link to activate button</div>
                </ContentWrapperDiv>             
        </ModalDiv>
    )
}

const MintConditionsModal = (props) => {

    
        return (
            <React.Fragment>
                {ReactDOM.createPortal(
                    <MintConditionsImplementation onClose={props.onClose} />
                    ,
                    document.getElementById('modal-mintconditions-root')
                )}
            </React.Fragment>
        );
    
}

export default MintConditionsModal;