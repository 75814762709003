import styled from "styled-components";
import AppMenu from "../components/menu";
import bgImg from '../assets/app_bg.png';
import { useState } from "react";


const PageWrapperDiv = styled.div` 
    display: flex;    
    justify-content: center;
    align-content: center;    
    width: 100%;    
    background-image:  url(${bgImg});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;        
    @media screen and (max-width: 768px) {      
        margin: 0px;
        width: 100%;        
        
        // margin-top: 85px;        
    }
`;

const DocsWrapperDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative; 
    margin-bottom: auto; 
    max-height: 80%;  
    @media screen and (max-width: 844px) {  
       
    }
    @media screen and (max-width: 768px) {                          
        justify-content: flex-start;
        width: 95%;  
        
    }
`;


const SectionDiv = styled.section`
    display: flex;
    flex-direction: column;    
    width: 75%;
    background: linear-gradient(to bottom, #87ceeb,  #A4DCF3);
    color:  #060fce;
    border: 1px solid #060fce;;
    border-radius: 5px;    
    padding: 18px;
    font-family: Laila Bold;
    font-size: 1.5em;
    position: relative;    
    top: 45px;    
    overflow-y: auto;
    row-gap: 0.5rem;
    scrollbar-color: #787EF9 #151ED9;
    scrollbar-width: thin;
    @media screen and (max-width: 768px) {
        width: 90%;
        font-size: 1.0em;
        min-height: 0px;
        height: 75%;
        border-radius: 5px;
        top: 20px;
        padding: 10px;
              
     }
`;

const TextDiv = styled.div`

font-family: Laila Bold;
font-size: .9em;
color: #060fce;
`;

const TermsHeaderDiv = styled.div`
font-family: Laila Bold;
font-size: 1.1em;
color: #060fce;
padding: 5px 0px 15px 0px;
font-weight: boldest;
align-self: center;

`;

const TermsDiv = styled.div`
display: flex;
flex: 1
overflow-y: auto;
flex-direction: column;
font-family: Laila Bold;
font-size: .9em;
color: #060fce;

`;


const SeedsNavigationDiv = styled.div`
    display: flex;
    width: 30%;
    justify-content: space-between;   
    padding: 20px;
    position: relative;
    top: 25px;
    border-top: 1px solid #060fce;
    margin-top: 5px;
    @media screen and (max-width: 768px) {             
      width: 80%;  
      padding: 10px;    
    }
`;


const StyledButton = styled.button`
    position: relative;
    font-family: Laila Bold;
    color: white;
    border: 1px solid #060fce;
    border-radius: 90px;
    background-color: #060fce;    
    width: auto;
    padding: 20px;
    font-size: 1em;
    min-width: 100px;
    max-width: 300px;
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      font-size: .7em;
      min-width: 0px;
      width: 75px;
      padding: 5px;     
    }
`;
const DocsPage = () => {
    const [display, SetDisplay] = useState("HOW");

    const onClick = (args) => {
        SetDisplay(args);
    }

    return(
        <PageWrapperDiv>
            <DocsWrapperDiv>
                <AppMenu /> 
                <SeedsNavigationDiv >
                    <StyledButton onClick={() => onClick("HOW")}>How it works</StyledButton>
                     <StyledButton onClick={() => onClick("TERMS")}>Disclaimer</StyledButton>                    
                </SeedsNavigationDiv>
                {
                    display === "TERMS" && 
                <SectionDiv>
                        <TermsHeaderDiv>Disclaimer</TermsHeaderDiv>
                        <div>
                            <TermsHeaderDiv>Use of the Sapphire Garden implies your accpetance of the following terms and disclaimer.</TermsHeaderDiv>
                        </div>
                        <div>
                        <TermsHeaderDiv>Gnomey NFTs and Sapphire Garden tokens, current and/or future, are herein referred to as "the tokens".</TermsHeaderDiv>
                        </div>
                        <div>
                            <TermsHeaderDiv style={{color: "black"}}>No Guarantee of Value or Profit</TermsHeaderDiv>
                            <TermsHeaderDiv>You understand and acknowledge that you should have no reasonable expectation of future appreciation or monetary return resultant of acquiring the tokens. There is no future business, revenue, earnings, or value from holding the tokens. There is also no promise or guarantee that any partner tokens provided as yield will have any value whatsoever.</TermsHeaderDiv>
                        </div>
                        <div>
                            <TermsHeaderDiv style={{color: "black"}}>No Utility or Governance Rights</TermsHeaderDiv>
                            <TermsHeaderDiv>Ownership of the tokens, today or in the future, do not represent any economic ownership of any kind whatsoever and are not instruments of equity in any legal entity, structure, association, or any other commercial or non-commercial enterprise.</TermsHeaderDiv>
                        </div>
                        <div>
                            <TermsHeaderDiv style={{color: "black"}}>No Future Accretion, Utility, or Value Expectation </TermsHeaderDiv>
                            <TermsHeaderDiv>The tokens will never be more than a useless memecoin. Any plans for further development that may arise, if ever, surrounding the tokens are merely for experimental and/or entertainment purposes. You understand and acknowledge that you should not be holding the tokens, reasonably expecting for any development to occur. Holding the tokens is at your own risk, and holders should acknowledge that Web3 smart contracts are very high risk in nature.</TermsHeaderDiv>
                        </div>
                        <div>
                            <TermsHeaderDiv style={{color: "black"}}>No Future Works</TermsHeaderDiv>
                            <TermsHeaderDiv>Any mention of any future works or developments, implied or otherwise is not and does not constiture any guarantee promise that said work or development will be completed by Sapphire Garden. Any mentions of work or development on any platform or medium is not a promise of said work. The work may never happen or may be altered. You should not purchase an of the tokens based on such statements of work or development.</TermsHeaderDiv>
                        </div>
                        <div>
                            <TermsHeaderDiv style={{color: "black"}}>Gnomey NFT Full transfer of ownership</TermsHeaderDiv>
                            <TermsHeaderDiv>Gnomey NFTs are sold as a digital collectible. The Gnomey NFT is not an investment or contract and you should have no expectations as such. Upon purchasing, you agree that you are surrendering the said cost of the purchase token to Sapphire Garden in exchange for complete ownership of the Gnomey NFT. Gnomey NFTs are randomly generated. There is no guarantee of any rarity. After successful purchase, the buyer has full and complete rights to the Gnomy NFT as a digital collectible. There is no guarantee or assumption provided that the Gnomey NFT will have any future value.</TermsHeaderDiv>
                        </div>
                        <div>
                            <TermsHeaderDiv style={{color: "black"}}>Acknowledgement</TermsHeaderDiv>
                            <TermsHeaderDiv>Where you have chosen to acquire the tokens, you acknowledge and agree that you have read, understood, and accepted all of the risks and information outlined above.</TermsHeaderDiv>
                        </div>
                    
                </SectionDiv>              
                }
                {display === "HOW" && 
            
                <SectionDiv>                   
                    <TextDiv>Sapphire Garden's purpose is to unite the many communities of the Oasis Sapphire ecosystem as it grows.</TextDiv>
                    <TextDiv>The Sapphire Garden is a trustless, renounced, open protocol: </TextDiv>
                    <TextDiv>
                        <li>The garden's beauty attracts partners that would like to have their token provided as yield.</li>                        
                        <li>Then, we launch a new token in the Sapphire Garden. A beautful flower that provides perpetual, trustless yield to holders.</li>
                        <li>Each flower token in the Garden has a higher slippage rate.</li>
                        <li>The higher slippage rate (aka. fee on buy and sell) allows the token to burn its own supply and buy the reward token from the market</li>
                        <li>The reward token is then distributed to holders of the Garden token proportional to the % they hold.</li>
                    </TextDiv>
                    <TextDiv>
                        It all starts with the Gnomey NFT. When a new flower is launched, 45% of the supply of the new flower token is air-dropped for each Gnomey to hold onto.
                    </TextDiv>
                    <TextDiv>
                        Gnomey's are generative 1-of-1 NFTs with a limited supply. But, whoever is able to get one, will be get all Garden airdrops forever.
                    </TextDiv>
                    <TextDiv>
                        All Garden tokens are fair-launched without any presale. They are distributed via airdrop to wallet address that hold Gnomey NFT(s).
                    </TextDiv>
                    <TextDiv>
                        The protocol provides an incentive for participants to create liquidity and just hold it in your wallet. By doing so, you will earn 2x yield rewards.
                    </TextDiv>                                     
                </SectionDiv>
                }
                
            </DocsWrapperDiv>
            
        </PageWrapperDiv>
    )
}

export default  DocsPage;