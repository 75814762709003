import { createSlice } from '@reduxjs/toolkit';

const sideDrawerSlice = createSlice({
    name: 'sideDrawerState',
    initialState: {showbackDrop: false, showSideDrawer: false},
    reducers: {
        setBackDropState(state, action) {              
            state.showbackDrop = action.payload;            
        },
        setSideDrawerOpen(state, action){                 
            state.showSideDrawer = action.payload;
        }
    }
});

export const SideDrawerStateActions = sideDrawerSlice.actions;

export default sideDrawerSlice.reducer;