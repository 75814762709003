import React from "react";
import { useEffect } from "react";
import ReactDOM from 'react-dom';
import styled from  'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { UserMessageStateActions } from "../store/state/usermessagestate";

const ModalDiv = styled.div`
--Example: 400px;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color: #ecf3ec;
    color: black;
    top: 25px;
    left: calc(50% - var(--Example)/2);  
    width: 400px;  
    min-height: 75px;
    z-index: 100;
    overflow: hidden;
    border: 3px outset #C4C5C6;
    border-radius: 8px;
    padding-bottom: 10px;
    padding: 10px;
    :hover{
        cursor: pointer;
    }
    @media screen and (max-width: 768px) {
        justify-self: center;   
        left: 10px;     
        width: 90%;
    }
`;



const InfoMessageModalImplementation = (props) => {
    const userMessage = useSelector(state => state.userMessageReduxState);
    const dispatch = useDispatch();

    const onCancelInfoMsgModal = () => {
        dispatch(UserMessageStateActions.setUserMessage({show: false, message:``}));  
    }

    useEffect(() => {
        if(userMessage.show){            
            setTimeout(onCancelInfoMsgModal, 3000);
        }
       
    },[props.oncancel, userMessage.show])
  
    return (
        <ModalDiv onClick={onCancelInfoMsgModal} >            
                <div style={{maxWidth: "100%"}}>
                    {userMessage.message}
                </div>             
        </ModalDiv>
    )
}

const InfoMessageModal = (props) => {

    
        return (
            <React.Fragment>
                {ReactDOM.createPortal(
                    <InfoMessageModalImplementation />
                    ,
                    document.getElementById('modal-infomsg-root')
                )}
            </React.Fragment>
        );
    
}

export default InfoMessageModal;