import { createSlice } from '@reduxjs/toolkit';


const authStateSlice = createSlice({
    name: 'authState',
    initialState: {isAuthenticated: false,  walletAddress: '', profile: null},
    reducers: {
        logOn(state, action) {
            state.isAuthenticated = action.payload.isAuthenticated;            
            state.walletAddress = action.payload.walletAddress;                        
        },
        logOff(state){
            state.isAuthenticated = false;                       
            state.walletAddress = null;
        } 
    }
});

export const AuthStateActions = authStateSlice.actions;

export default authStateSlice.reducer;