import styled from "styled-components";



const SectionTextDiv = styled.section`
    display: flex;
    flex-direction: column;    
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
    width: 80%;
    background: linear-gradient(to bottom, #87ceeb,  #A4DCF3);
    color:  #060fce;
    border: 1px solid #060fce;;
    border-radius: 45px;
    min-height: 345px;
    padding: 18px;
    font-family: Laila Bold;
    font-size: 1.5em;
    position: relative;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 768px) {
        width: 90%;
        padding: 3px;
        top: 0px;
        row-gap: 0.5em; 
        font-size: 1em; 

    }
`;

const TextDiv = styled.div`
font-family: Laila Bold;
@media screen and (max-width: 768px) {
        width: 90%;
}
`;

const SeedsWrapperDiv = styled.div`
    display: flex;    
    justify-content: center;
    column-gap: 2rem;
    position: relative;
    top: 10px;
    height: 500px;
    width: 80%;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      top: 0px;
     
    }
`


const YourProjectHereSeeds = () => {    
    
     return(
        <SeedsWrapperDiv>   
            <SectionTextDiv >                                
                <TextDiv>Plant a new seed in the Sapphire garden!</TextDiv>
                <TextDiv>Have your token bought and distributed as yield.</TextDiv>
                <TextDiv>Have your project token listed here.</TextDiv>
            </SectionTextDiv>
        </SeedsWrapperDiv>   
     )   
    }
       
   
   
   export default YourProjectHereSeeds;