import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const BackDropModalDiv = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
z-index: 10;
background: rgba(0, 29, 41, 0.8);
`;

const BackDrop = (props) => {

    return (        
        <BackDropModalDiv onClick={props.clickHandler}></BackDropModalDiv>
    )
}


const ModalBackDrop = (props) => {
    return (
        <React.Fragment>
            {
                ReactDOM.createPortal(<BackDrop clickHandler={props.backDropClick} />, 
                document.getElementById('modal-backdrop-root')
                )
            }    
        </React.Fragment>
    );
}

export default ModalBackDrop;