import { useEffect, useState } from "react";
import { StyledButton } from ".."
import { useDispatch, useSelector } from "react-redux";
import {ethers} from 'ethers';
import { AuthStateActions } from '../../store/state/authstate';
import networkConfig from '../../config';
import { UserMessageStateActions } from "../../store/state/usermessagestate";
import styled from "styled-components";

const ButtonDiv = styled.div`
@media screen and (max-width: 768px) {   
    font-size: .7em;
        
}
`;
const WalletButton = () => {
    const [buttonText, setButtonText] = useState("Login");
    
    const authStatus= useSelector(state => state.authReduxState.isAuthenticated)
    const dispatch = useDispatch();
    

    const GetCurrentAddress = async () => {
        // console.log("GetCurr")
        const provider  = new ethers.providers.Web3Provider(window.ethereum,"any");      
        await window.ethereum.request({method: 'eth_requestAccounts'}).then( async t => {
            const signer = provider.getSigner();
            const addy = await signer.getAddress(); 
            dispatch(AuthStateActions.logOn({isAuthenticated: true,  walletAddress: addy}));
            setButtonText("0x..." +addy.slice(-4) )            
        })
        .catch( e => {
            dispatch(UserMessageStateActions.setUserMessage({show: true, message: e.message}));
            return [undefined, undefined, undefined];
        });          
    }

    useEffect(() => {
        // console.log("auth stat", authStatus);
        const Init = async () => {
            if(window.ethereum){
                window.ethereum.on('chainChanged', (chainId) => { 
                    if(chainId !== networkConfig.chainID)  {                    
                        setButtonText("Login");               
                        window.location.reload();
                    }
                })
    
                window.ethereum.on('accountsChanged', (chainId) => {                     
                    localStorage.removeItem("token");
                    localStorage.removeItem("address"); 
                    setButtonText("Login");    
                    dispatch(AuthStateActions.logOff());
                    window.location.reload();
                })

                if(authStatus){
                    await GetCurrentAddress(); 
                }
            }
        }

        Init();

    });

    const LogOnClickHandler = async () => {
        if(!authStatus){
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            console.log("current chainID", chainId);
            if(chainId !==  networkConfig.chainID){
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: networkConfig.chainID }],
                }).then ( async t => {                    
                    await GetCurrentAddress();                    
                }).catch( c => {
                    dispatch(UserMessageStateActions.setUserMessage({show: true, message: c.message + ` Must be connected to ${networkConfig.name}`}));
                    
                    
                });
            }else{
                await GetCurrentAddress();
            }
     
        } else {
            dispatch(AuthStateActions.logOff());
            setButtonText("Login");            
        }
    }
    return(
         <StyledButton onClick={LogOnClickHandler} >
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <ButtonDiv>{buttonText}</ButtonDiv>                
            </div>
        </StyledButton> 
    )
}

export default WalletButton