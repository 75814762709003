import { StyledButton } from "..";
import WalletButton from "../wallet";
import tgLogo from '../../assets/tg_logo.png';
import xLogo from '../../assets/x_logo_white.png';
import { useNavigate } from "react-router";
import styled from "styled-components";


const MenuWrapper = styled.div`
display: flex;
flex-direction: column;
position: relative;
padding: 25px;
align-items: center;
width: 100%;

@media screen and (max-width: 768px) {
}
`;

const SocialsWrapper = styled.div`
 display: flex;
 align-items: center;
 column-gap: 2rem;

@media screen and (max-width: 768px) {
  
}
`;

const MenuButtonsWrapper = styled.div`
  display: flex;
  column-gap: 1rem;

@media screen and (max-width: 768px) {
  width: 98%;
  column-gap: 0.5rem;
  justify-content: center;
}`
;


const AppMenu = () => {
    const navigate = useNavigate();

    const onMenuClick = (page) =>{
        if(page === "NFT"){
          navigate("/nft");
        }else if(page === "SEEDS"){
          navigate("/seeds/orchids");
        }else if(page === "GARDEN"){
          navigate("/garden");
        }else if(page === "HOME"){
          navigate("/");
        }else if(page === "DOCS"){
          navigate("/docs");
        }
      }

    return(
        <MenuWrapper>
            <SocialsWrapper>
                <div><a href="https://twitter.com/SapphiregardIO" target="_blank" rel="noreferrer" ><img style={{height: "15px"}} src={xLogo} alt=""/></a></div>
                <div><a href="https://t.me/sapphiregardenio" target="_blank" rel="noreferrer"><img style={{height: "25px"}} src={tgLogo} alt="" /></a></div>
            </SocialsWrapper>
            <MenuButtonsWrapper >
                <StyledButton onClick={() => onMenuClick("HOME")}>Home</StyledButton>
                <StyledButton onClick={() => onMenuClick("NFT")}>NFT</StyledButton>
                <StyledButton onClick={() => onMenuClick("SEEDS")}>Seeds</StyledButton>
                <StyledButton onClick={() => onMenuClick("GARDEN")}>Garden</StyledButton>
                <StyledButton onClick={() => onMenuClick("DOCS")}>Docs</StyledButton>
                <WalletButton />
            </MenuButtonsWrapper>
        </MenuWrapper>
    )
}

export default AppMenu;